import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import SaveIcon from '@mui/icons-material/Save';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';

import Ayarlar2 from './Ayarlar2';

import Avatar from '@mui/material/Avatar';
import { deepOrange } from '@mui/material/colors';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';


import axios from 'axios';

export default function Ayarlar() {

  const id = 1;
  // const [textValue, setTextValue] = useState("");
  // const onTextChange = (e) => setTextValue(e.target.value);

  useEffect(() => {
    const fetchData = async () => {
      const res = await fetch(
        `https://aktivite-app.herokuapp.com/ayarlar?id=${id}`,
      );
      const json = await res.json();
      // console.log(json.data)
      if(json.data && !(json.data[0].minDate == 0)){
        setMinDate(json.data[0].minDate);
        setMinInputDate(new Date((json.data[0].minDate * 1000) + 10800).toISOString().split('T')[0]);
      }
      if(json.data && !(json.data[0].maxDate == 0)){
        setMaxDate(json.data[0].maxDate);
        setDefaultDate(new Date((json.data[0].maxDate * 1000) + 10800).toISOString().split('T')[0]);
      }
    };
    fetchData();
  }, []);

  const [maxDate, setMaxDate] = useState(0);
  const [defaultDate, setDefaultDate] = useState("");
  const handleChangeMaxDate = (event) => {
    setMaxDate(Math.floor(new Date(event.target.value).getTime() / 1000));
    setDefaultDate(new Date(event.target.value).toISOString().split('T')[0]);
  };

  const [minDate, setMinDate] = useState(0);
  const [minInputDate, setMinInputDate] = useState("");
  const handleChangeMinDate = (event) => {
    // console.log(Math.floor(new Date(event.target.value).getTime() / 1000));
    // console.log(new Date(event.target.value).getTime() / 1000);
    setMinDate(Math.floor(new Date(event.target.value).getTime() / 1000)); //+3GMT için sn çıkarma işlemi
    setMinInputDate(new Date(event.target.value).toISOString().split('T')[0]);
  };

  const handleReset = async () => {
    const data = { 
      minDate: 0,
      maxDate: 0,
    };
    // console.log(data);
    await axios.put(`https://aktivite-app.herokuapp.com/ayarlar/${id}`, data)
    .then(function (response) {
      console.log(response);
      setMinInputDate("");
      setDefaultDate("");
    })
    .catch(function (error) {
      console.log(error);
      alert(error);
    });
  };

  const handleSave = async () => {
    const data = { 
      minDate: minDate,
      maxDate: maxDate,
    };
    // console.log(data);
    await axios.put(`https://aktivite-app.herokuapp.com/ayarlar/${id}`, data)
    .then(function (response) {
      console.log(response);
    })
    .catch(function (error) {
      console.log(error);
      alert(error);
    });
  };
  
  return (
    <div style={{ height: '90%', width: '100%' }}>
      <Ayarlar2 />

      <Divider variant="middle" flexItem sx={{ my: 5 }} />

      <Typography variant="h5" align="left" gutterBottom component="div" sx={{ m: 2 }}>
        Admin için Panel Ayarları
      </Typography>
      <Typography variant="body1" align="left" gutterBottom component="div" sx={{ m: 2 }}>
        Aktivite Girişleri için ayarlar:
      </Typography>

      <Stack 
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
        sx={{ mb: 2 }}
      >
      <Box component="form" sx={{ display: 'grid', gridAutoFlow: 'row', gridTemplateColumns: 'repeat(6, 1fr)', gridTemplateRows: 'repeat(2, 1fr)', }}>
        <FormControl sx={{ m: 1, gridRow: '1', gridColumn: 'span 3' }}>
          <TextField
            label="Başlangıç Tarihi"
            id="startDate"
            type="date"
            size="small"
            defaultValue={minInputDate}
            value={minInputDate}
            onChange={handleChangeMinDate}
            InputLabelProps={{ shrink: true }} //label'ı oto. üste alır
          />
        </FormControl>
        <FormControl sx={{ m: 1, gridRow: '1', gridColumn: 'span 3' }}>
          <TextField
            label="Bitiş Tarihi"
            id="endDate"
            type="date"
            size="small"
            defaultValue={defaultDate}
            value={defaultDate}
            onChange={handleChangeMaxDate}
            InputLabelProps={{ shrink: true }} //label'ı oto. üste alır
          />
        </FormControl>
        <FormControl sx={{ m: 1, gridRow: '2', gridColumn: 'span 3' }}>
          <Button 
            onClick={() => { handleReset(); }}
            variant="contained" 
            endIcon={<RestartAltIcon />} 
            // sx={{ minWidth: 150 }}
          >
            Reset
          </Button>
        </FormControl>
        <FormControl sx={{ m: 1, gridRow: '2', gridColumn: 'span 3' }}>
          <Button 
            onClick={() => { handleSave(); }}
            variant="contained" 
            endIcon={<SaveIcon />} 
            // sx={{ minWidth: 150 }}
          >
            Kaydet
          </Button>
        </FormControl>
      </Box>
      </Stack>
    </div>
  );
}
