import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import AddIcon from '@mui/icons-material/AddCircleOutline';

import axios from 'axios';

export default function DialogSelect({addRow}) {
  const [open, setOpen] = React.useState(false);
  const [name, setName] = React.useState("");
  const [mail, setMail] = React.useState("");
  const [pass, setPass] = React.useState("");
  const [yetki, setYetki] = React.useState(3);

  const handleChangeName = (event) => {
    setName(event.target.value);
  };

  const handleChangeMail = (event) => {
    setMail(event.target.value);
  };
  
  const handleChangePass = (event) => {
    setPass(event.target.value);
  };

  const handleChangeYetki = (event) => {
    setYetki(Number(event.target.value) || '');
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setOpen(false);
    }
  };

  const handleAdd = async () => {
    const options = {
      headers: {
          'Content-Type': 'application/json',
      }
    };
    
    const data = { ad: name, email: mail, password: pass, yetki: yetki };
    
    await axios.post('https://aktivite-app.herokuapp.com/kullanicilar', data, options)
    .then(function (response) {
      // console.log(response);
      addRow({  id: response.data.message, 
                ad: name,
                email: mail,
                yetki: yetki == 1 ? 'Admin' : 'Standard'
              });
      // alert(response.data.message + " " + textValue + " başarıyla eklendi");
    })
    .catch(function (error) {
      console.log(error);
      alert(error);
    });

    setOpen(false);
    setName("");
    setMail("");
    setPass("");
    setYetki(3);
  };

  return (
    <div>
      <Button onClick={handleClickOpen} startIcon={<AddIcon />}>
        YENİ KULLANICI GİRİŞİ
      </Button>
      <Dialog disableEscapeKeyDown open={open} onClose={handleClose}>
        <DialogTitle>Kullanıcı eklemek için bilgileri eksiksiz doldurunuz</DialogTitle>
        <DialogContent>
          <Box component="form" sx={{ display: 'grid', gridAutoFlow: 'row', gridTemplateColumns: 'repeat(6, 1fr)', gridTemplateRows: 'repeat(2, 1fr)', }}>
            <FormControl sx={{ m: 1, gridRow: '1', gridColumn: 'span 3' }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Adresi"
                name="email"
                // autoComplete="email"
                value={mail}
                onChange={handleChangeMail}
              />
            </FormControl>
            <FormControl sx={{ m: 1, gridRow: '1', gridColumn: 'span 3' }}>
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                id="password"
                label="Şifre"
                type="password"
                // autoComplete="current-password"
                value={pass}
                onChange={handleChangePass}
              />
            </FormControl>
            <FormControl sx={{ m: 1, gridRow: '2', gridColumn: 'span 3' }}>
              <TextField
                required
                id="name"
                label="Ad Soyad"
                value={name}
                onChange={handleChangeName}
              />
            </FormControl>
            <FormControl sx={{ m: 1, gridRow: '2', gridColumn: 'span 3' }}>
              <InputLabel id="yetki-select-label">Yetki</InputLabel>
              <Select
                labelId="yetki-select-label"
                id="yetki-select"
                value={yetki}
                onChange={handleChangeYetki}
                input={<OutlinedInput label="Yetki" />}
              >
                <MenuItem value={1}>Admin</MenuItem>
                <MenuItem value={3}>Standard</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>İptal</Button>
          <Button onClick={handleAdd}>EKLE</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}