import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import AddIcon from '@mui/icons-material/AddCircleOutline';

import axios from 'axios';

export default function DialogSelect({addRow}) {
  const [open, setOpen] = React.useState(false);
  const [customer, setCustomer] = React.useState("");
  const [partner, setPartner] = React.useState("");
  const [proje, setProje] = React.useState();
  const [efor, setEfor] = React.useState();
  const [aciklama, setAciklama] = React.useState("");

  let partners = null;
  let projeler = null;
  
  const [customerArray, setCustomerArray] = useState([]);
  // useEffect(() => {
  //   const fetchData = async () => {
  //     const res = await fetch(
  //       'https://aktivite-app.herokuapp.com/musteriler',
  //     );
  //     const json = await res.json();
  //     setCustomerArray(json.data);
  //   };
  //   fetchData();
  // }, [setCustomerArray]);
  // let customers = customerArray.map((item, i) => (<MenuItem value={item.id}>{item.name}</MenuItem>));
  
  // const [partnerArray, setPartnerArray] = useState([]);
  // useEffect(() => {
  //   const fetchData = async () => {
  //     const res = await fetch(
  //       'https://aktivite-app.herokuapp.com/partnerler',
  //     );
  //     const json = await res.json();
  //     setPartnerArray(json.data);
  //   };
  //   fetchData();
  // }, [setPartnerArray]);
  // // let partners = partnerArray.map((item, i) => (<MenuItem value={item.id}>{item.name}</MenuItem>));

  
  const [projeArray, setProjeArray] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const res = await fetch(
        'https://aktivite-app.herokuapp.com/projeler?sort=customer',
      );
      const json = await res.json();
      
      var lookup = {};
      var items = json.data;
      var result = {};

      for (var item, i = 0; item = items[i++];) {
        var name = item.customer;
        var id = item.cid;

        //sorting için name-id yer değiştirdik
        if (!(id in lookup)) {
          lookup[id] = 1;
          result[name] = id;
        }
      }
      // console.log(result)
      setCustomerArray(result);
      // setPartnerArray(result);
      setProjeArray(json.data);

    };
    fetchData();
  }, []);
  // let customers = customerArray.map((item, i) => (<MenuItem value={item.id}>{item.name}</MenuItem>));
  // console.log(customerArray);
  let customers = Object.entries(customerArray).map(([key, value]) => (<MenuItem value={value}>{key}</MenuItem>));
  // console.log(customers)
  // Object.entries(customerArray).map(([key, value]) => (console.log(value)));
  // Object.entries(customerArray).forEach(([key, value]) => (<MenuItem value={key}>{value}</MenuItem>));
  // console.log(customers);
  


  
  // const setPartnerArray = (value) => {
    const filtered = projeArray.filter(item => item.cid == customer); //seçilen customer için tüm ihtimaller
    const partnerFiltered = filtered.filter( (item, index) => index === filtered.findIndex( element => element.pid === item.pid)); //seçilen customer için distinct partners
    const projeFiltered = projeArray.filter(item => item.cid == customer && item.pid == partner); // seçilen customer+partner ikilisini içeren projeler
  
    partners = partnerFiltered.map((item) => (<MenuItem value={item.pid}>{item.partner}</MenuItem>));
    projeler = projeFiltered.map((item) => (<MenuItem value={item.id}>{item.proje}</MenuItem>));
  // };
  
  
  const handleChangeEfor = (event) => {
    setEfor(Number(event.target.value) || '');
  };

  const handleChangeAciklama = (event) => {
    setAciklama(event.target.value);
  };

  //ELEYEN USER ID
  const user = sessionStorage.getItem('user');
  // const [user, setUser] = useState(106);
  // const handleChangeUser = (event) => {
  //   setUser(event.target.value);
  // };
  
  const handleChangeCustomers = (event) => {
    setCustomer(event.target.value);
    setPartner("");
    setProje();
  };

  const handleChangePartner = (event) => {
    setPartner(event.target.value);
    setProje();
  };

  const handleChangeProje = (event) => {
    setProje(event.target.value);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setOpen(false);
    }
  };

  const handleAdd = () => {
    let isError = false;
    if(unixDate > Math.floor(new Date(defaultDate).getTime() / 1000)
    || unixDate < Math.floor((new Date(minInputDate).getTime() / 1000) - 10800)){
      isError = true;
      alert("Geçerli bir tarih giriniz!");
    }
    if(efor < 1 || !(efor)){
      isError = true;
      alert("Geçerli bir efor giriniz!");
    } 
    if(!(proje)){
      isError = true;
      alert("Geçerli bir proje seçiniz!");
    } 
    if(!(aciklama)){
      isError = true;
      alert("Geçerli bir açıklama giriniz!");
    } 
    if(!(user)){
      isError = true;
      alert("Kullanıcı tanınmadı, yöneticiye ulaşınız.");
    } 
    if(!isError){
      handleAdd2();
    }
  }

  const handleAdd2 = async () => {

    const options = {
      headers: {
          'Content-Type': 'application/json',
      }
    };
    
    const data = { date: unixDate, efor: efor, proje_id: proje, consultant: user, aciklama: aciklama };
    
    await axios.post('https://aktivite-app.herokuapp.com/aktiviteler', data, options)
    .then(function (response) {
      // console.log(response);
      const temp = projeArray.filter(item => item.cid == customer && item.pid == partner && item.id == proje )
      addRow({  id: response.data.message, 
                date: unixDate,
                consultant: temp[0].consultant,
                efor: efor, 
                customer: temp[0].customer, 
                partner: temp[0].partner, 
                proje: temp[0].proje, 
                aciklama: aciklama,
                is_modified: null,
                mod_date: null,
                mod_user: null
              });
      // alert(response.data.message + " " + textValue + " başarıyla eklendi");
    })
    .catch(function (error) {
      console.log(error);
      alert(error);
    });

    setOpen(false);
    setCustomer("");
    setPartner("");
    setProje();
    setEfor();
    setAciklama("");
  };



  const todaysDate = new Date(new Date().setHours(3, 0, 0, 0));
  const minDate = new Date();
  // Ayın ilk haftası önceki aya giriş yapılabilsin
  if(minDate.getDate() < 7){
    minDate.setMonth(minDate.getMonth() - 1);
    // 31 marttan geri dönünce 31 şubat yani 3 mart yapmaması için kod
    if (minDate.getMonth() == todaysDate.getMonth()) 
      minDate.setDate(0);
  }
  minDate.setDate(1);
  // minDate.setHours(0, 0, 0, 0);
  minDate.setUTCHours(0, 0, 0, 0);

  const [defaultDate, setDefaultDate] = useState(todaysDate.toISOString().split('T')[0]);
  const [minInputDate, setMinInputDate] = useState(minDate.toISOString().split('T')[0]);

  const [date, setDate] = useState(todaysDate.toISOString().split('T')[0]);
  const [unixDate, setUnixDate] = useState(Math.floor(new Date(new Date().setHours(0, 0, 0, 0)).getTime() / 1000));
  const handleChangeDate = (event) => {
    setDate(new Date(event.target.value).toISOString().split('T')[0]);
    setUnixDate(Math.floor(new Date(event.target.value).getTime() / 1000) - 10800); //+3GMT için sn çıkarma işlemi
  };

  //ayarlar ekranından aktivite giriş kısıtlaması girilmiş ise:
  const id = 1;
  useEffect(() => {
    const fetchData = async () => {
      const res = await fetch(
        `https://aktivite-app.herokuapp.com/ayarlar?id=${id}`,
      );
      const json = await res.json();
      // console.log(json.data)
      if(json.data && !(json.data[0].minDate == 0)){
        setMinInputDate(new Date((json.data[0].minDate * 1000) + 10800).toISOString().split('T')[0]);
      }
      if(json.data && !(json.data[0].maxDate == 0)){
        setDefaultDate(new Date((json.data[0].maxDate * 1000) + 10800).toISOString().split('T')[0]);
        setDate(new Date((json.data[0].maxDate * 1000) + 10800).toISOString().split('T')[0]);
        setUnixDate(json.data[0].maxDate);
      }
    };
    fetchData();
  }, []);


  return (
    <div>
      <Button onClick={handleClickOpen} startIcon={<AddIcon />}>
        YENİ AKTİVİTE GİRİŞİ
      </Button>
      <Dialog disableEscapeKeyDown open={open} onClose={handleClose}>
        <DialogTitle>Aktivite Girişi yapabilmek için bilgileri eksiksiz doldurunuz</DialogTitle>
        <DialogContent>
          <Box component="form" sx={{ display: 'grid', gridAutoFlow: 'row', gridTemplateColumns: 'repeat(6, 1fr)', gridTemplateRows: 'repeat(3, 1fr)', }}>
            <FormControl sx={{ m: 1, gridRow: '1', gridColumn: 'span 3' }}>
              <TextField
                label="Tarih"
                id="date"
                type="date"
                value={date}
                onChange={handleChangeDate}
                InputLabelProps={{ shrink: true }} //label'ı oto. üste alır
                InputProps={{
                  inputProps:{ min: minInputDate, max: defaultDate }
                }}
              />
            </FormControl>
            <FormControl sx={{ m: 1, gridRow: '1', gridColumn: 'span 3' }}>
              <TextField
                label="Efor"
                id="efor"
                type="number"
                value={efor}
                onChange={handleChangeEfor}
                // sx={{ m: 1, width: '25ch' }}
                InputProps={{
                  // inputMode: 'numeric', pattern: '[0-9]*',
                  inputProps: { min: "0" },
                  endAdornment: <InputAdornment position="end">Saat</InputAdornment>
                }}
              />
            </FormControl>
            <FormControl sx={{ m: 1, gridRow: '2', gridColumn: 'span 2' }}>
              <InputLabel htmlFor="customer-select-label">Müşteriler</InputLabel>
              <Select
                labelId="customer-select-label"
                id="customer-select"
                value={customer}
                onChange={handleChangeCustomers}
                input={<OutlinedInput label="Müşteriler" />}
              >
                {customers}
              </Select>
            </FormControl>
            <FormControl sx={{ m: 1, gridRow: '2', gridColumn: 'span 2' }}>
              <InputLabel id="partner-select-label">Partnerler</InputLabel>
              <Select
                labelId="partner-select-label"
                id="partner-select"
                value={partner}
                onChange={handleChangePartner}
                input={<OutlinedInput label="Partnerler" />}
              >
                {partners}
              </Select>
            </FormControl>
            <FormControl sx={{ m: 1, gridRow: '2', gridColumn: 'span 2' }}>
              <InputLabel id="proje-select-label">Proje</InputLabel>
              <Select
                labelId="proje-select-label"
                id="proje-select"
                value={proje}
                onChange={handleChangeProje}
                input={<OutlinedInput label="Proje" />}
              >
                {projeler}
              </Select>
            </FormControl>
            <FormControl sx={{ m: 1, gridRow: '3', gridColumn: 'span 6' }}>
              <TextField
                id="outlined-multiline-flexible"
                label="Açıklama"
                multiline
                // maxRows={4}
                // Rows={2}
                value={aciklama}
                onChange={handleChangeAciklama}
              />
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>İptal</Button>
          <Button onClick={handleAdd}>EKLE</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}