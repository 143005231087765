import React from 'react';
import { Button, Paper, Typography } from '@mui/material';
import { Home } from '@mui/icons-material';

export default function NotFound() {
  return (
    <div
        style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: `100%`,
        }}
    >
        <Typography variant="h1">404</Typography>
        <Typography variant="subtitle1">
          Sayfa bulunamadı!
        </Typography>
        <Button
          color="primary"
          aria-label="home"
          href="/"
          style={{ marginTop: 20 }}
          variant="contained" 
          endIcon={<Home />} 
          sx={{ minWidth: 150 }}
        >
          Anasayfa
        </Button>
    </div>
  )
}