import React, { useState, useEffect } from 'react';
import { DataGrid, GridToolbarContainer } from '@mui/x-data-grid';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import TextField from '@mui/material/TextField';
import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import RestoreIcon from '@mui/icons-material/Restore';
import RefreshIcon from '@mui/icons-material/Refresh';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import Divider from '@mui/material/Divider';

import { IconButton } from '@mui/material';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import SimCardDownloadOutlinedIcon from '@mui/icons-material/SimCardDownloadOutlined';
import ExplicitOutlinedIcon from '@mui/icons-material/ExplicitOutlined';
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import DownloadSharpIcon from '@mui/icons-material/DownloadSharp';
import { red } from '@mui/material/colors';
import Tooltip from '@mui/material/Tooltip';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { utils, write, writeFile } from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import "../fonts/OpenSans-Regular";

function isOverflown(element) {
  return (
    element.scrollHeight > element.clientHeight ||
    element.scrollWidth > element.clientWidth
  );
}

const GridCellExpand = React.memo(function GridCellExpand(props) {
  const { width, value } = props;
  const wrapper = React.useRef(null);
  const cellDiv = React.useRef(null);
  const cellValue = React.useRef(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showFullCell, setShowFullCell] = React.useState(false);
  const [showPopper, setShowPopper] = React.useState(false);

  const handleMouseEnter = () => {
    const isCurrentlyOverflown = isOverflown(cellValue.current);
    setShowPopper(isCurrentlyOverflown);
    setAnchorEl(cellDiv.current);
    setShowFullCell(true);
  };

  const handleMouseLeave = () => {
    setShowFullCell(false);
  };

  React.useEffect(() => {
    if (!showFullCell) {
      return undefined;
    }

    function handleKeyDown(nativeEvent) {
      // IE11, Edge (prior to using Bink?) use 'Esc'
      if (nativeEvent.key === 'Escape' || nativeEvent.key === 'Esc') {
        setShowFullCell(false);
      }
    }

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [setShowFullCell, showFullCell]);

  return (
    <Box
      ref={wrapper}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      sx={{
        alignItems: 'center',
        lineHeight: '24px',
        width: 1,
        height: 1,
        position: 'relative',
        display: 'flex',
      }}
    >
      <Box
        ref={cellDiv}
        sx={{
          height: 1,
          width,
          display: 'block',
          position: 'absolute',
          top: 0,
        }}
      />
      <Box
        ref={cellValue}
        sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
      >
        {value}
      </Box>
      {showPopper && (
        <Popper
          open={showFullCell && anchorEl !== null}
          anchorEl={anchorEl}
          style={{ width, marginLeft: -17 }}
        >
          <Paper
            elevation={1}
            style={{ minHeight: wrapper.current.offsetHeight - 3 }}
          >
            <Typography variant="body2" style={{ padding: 8 }}>
              {value}
            </Typography>
          </Paper>
        </Popper>
      )}
    </Box>
  );
});

GridCellExpand.propTypes = {
  value: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
};

function renderCellExpand(params) {
  return (
    <GridCellExpand value={params.value || ''} width={params.colDef.computedWidth} />
  );
}

renderCellExpand.propTypes = {
  /**
   * The column of the row that the current cell belongs to.
   */
  colDef: PropTypes.object.isRequired,
  /**
   * The cell value, but if the column has valueGetter, use getValue.
   */
  value: PropTypes.string.isRequired,
};



export default function DataTable() {

  //EXCEL
  const handleExcel = () => {
    //TÜM DATAYI İNDİRİYOR PAGINATION YAPMIYOR
    const newData = rows.map(row => {
      delete row.id;
      const temp = {
        Tarih: new Date(row.tarih * 1000).toLocaleDateString(),
        Olusturan: row.user,
        Efor: row.saat,
        Musteri: row.musteri,
        Proje: row.ad,
        Duzenleyen: row.guser,
        Aciklama: row.aciklama
      }
      return temp;
    });
    const workSheet = utils.json_to_sheet(newData);
    const workBook = utils.book_new();
    utils.book_append_sheet(workBook, workSheet, "easyit-rapor");
    //Buffer
    let buf = write(workBook, { bookType:"xlsx", type:"buffer" });
    //Binary string
    write(workBook, { bookType:"xlsx", type:"binary" });
    //Download
    writeFile(workBook, "EasyIT-Oto-Rapor.xlsx");
  }

  //PDF
  const handlePDF = () => {
    const newData = rows.map(row => {
      const temp = {
        tarih: new Date(row.tarih * 1000).toLocaleDateString(),
        user: row.user,
        saat: row.saat,
        musteri: row.musteri,
        ad: row.ad,
        guser: row.guser,
        aciklama: row.aciklama
      }
      return temp;
    });
    const doc = new jsPDF();
    doc.text("EasyIT Rapor", 20, 10);
    doc.autoTable({
      theme: "grid",
      columns: columns.map(col => ({ ...col, dataKey: col.field })),
      body: newData,
      styles: {
        font: 'OpenSans-Regular',
        fontStyle: 'normal',
      }
    });
    doc.save('EasyIT-Oto-Rapor.pdf');
  }


  const columns = [
    // { field: 'id', headerName: 'ID', width: 70 },
    {
      field: 'tarih',
      headerAlign: 'center', 
      headerName: 'Tarih',
      type: 'date',
      width: 130,
      editable: false,
      align: 'center', 
      valueFormatter: (params) => {
        if (params.value == null) { return ''; }
        const valueFormatted = new Date(params.value * 1000).toLocaleDateString();
        return `${valueFormatted}`;
      }
    },
    { field: 'ad', headerName: 'Proje Adı', width: 200, renderCell: renderCellExpand},
    // { field: 'musteri', headerName: 'Müşteri', width: 130 },
    {
      field: 'saat',
      headerAlign: 'center', 
      headerName: 'Efor(Saat)',
      type: 'number',
      width: 90,
      editable: false,
      align: 'center'
    },
    { field: 'user', headerName: 'Danışman', width: 130, renderCell: renderCellExpand},
    { field: 'guser', headerName: 'Güncelleyen', width: 130, renderCell: renderCellExpand},
    {
      field: 'aciklama',
      headerName: 'Açıklama',
      sortable: false,
      flex: 1,
      editable: false,
      renderCell: renderCellExpand
    }
  ];

  //UNIX TIMESTAMP
  

  // const todaysDate = new Date();
  // const minDate = new Date();
  // minDate.setMonth(minDate.getMonth() - 1);
  // // 31 marttan geri dönünce 31 şubat yani 3 mart yapmaması için kod
  // if (minDate.getMonth() == todaysDate.getMonth()) 
  //   minDate.setDate(0);
  // minDate.setDate(1);
  // minDate.setHours(0, 0, 0, 0);

  // const defaultDate = todaysDate.toISOString().split('T')[0];
  // const minInputDate = minDate.toISOString().split('T')[0];


  const [maxDate, setMaxDate] = useState(Math.floor(new Date('2022-01-12T23:59:59').getTime() / 1000));
  const [defaultDate, setDefaultDate] = useState(new Date('2022-01-12T23:59:59').toISOString().split('T')[0]);
  const handleChangeMaxDate = (event) => {
    setMaxDate(Math.floor(new Date(event.target.value).getTime() / 1000));
    setDefaultDate(new Date(event.target.value).toISOString().split('T')[0]);
  };
  const [minDate, setMinDate] = useState(Math.floor(new Date('2021-12-12T03:00:00').getTime() / 1000));
  const [minInputDate, setMinInputDate] = useState(new Date('2021-12-12T03:00:00').toISOString().split('T')[0]);
  const handleChangeMinDate = (event) => {
    // console.log(Math.floor(new Date(event.target.value).getTime() / 1000) - 10800);
    // console.log(new Date(event.target.value).getTime() / 1000);
    setMinDate(Math.floor(new Date(event.target.value).getTime() / 1000) - 10800); //+3GMT için sn çıkarma işlemi
    setMinInputDate(new Date(event.target.value).toISOString().split('T')[0]);
  };
  
  const [user, setUser] = useState("");
  const handleChangeUser = (event) => {
    setUser(event.target.value);
  };
  const [musteri, setMusteri] = useState("");
  const handleChangeMusteri = (event) => {
    setMusteri(event.target.value);
  };
  const [proje, setProje] = useState("");
  const handleChangeProje = (event) => {
    setProje(event.target.value);
  };
  const [partner, setPartner] = useState("");
  const handleChangePartner = (event) => {
    setPartner(event.target.value);
  };

  const [rows, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    const res = await fetch(
      `https://aktivite-app.herokuapp.com/aktivitelerOld?startDate=${minDate}&endDate=${maxDate}&uid=${user}&musteri=${musteri}&proje=${proje}&partner=${partner}`,
    );
    const json = await res.json();
    if(json.data){
      // //eski kayıtlar UNIX Timestamp ile tutulmuş
      // for (var i=0; i<json.data.length; i++) {
      //   json.data[i].tarih = new Date(json.data[i].tarih * 1000).toLocaleDateString();
      // }
      setData(json.data);
    }
    setLoading(false);
  };
  useEffect(() => {
    fetchData();
  }, [setData]);


  const [userArray, setUserArray] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const res = await fetch(
        `https://aktivite-app.herokuapp.com/kullanicilar?sort=ad`,
      );
      const json = await res.json();
      setUserArray(json.data);
    };
    fetchData();
  }, [setUserArray]);
  let users = userArray.map((item, i) => (<MenuItem value={item.id}>{item.ad}</MenuItem>));
  

  const [pageSize, setPageSize] = React.useState(25);


  // const [editRowsModel, setEditRowsModel] = React.useState({});

  // const handleEditRowsModelChange = React.useCallback((model) => {
  //   setEditRowsModel(model);
  // }, []);


  const CustomToolbar = () => {

    const buttonBaseProps = {
      color: 'primary',
      size: 'small',
      // startIcon: <ExportIcon />,
    };
  
    return (
      <GridToolbarContainer>
        <Button
          {...buttonBaseProps}
          onClick={handleExcel}
          startIcon={<DownloadSharpIcon color="primary" />}
          sx={{ mx: 1 }}
        >
          Export to Excel
        </Button>

        <Divider orientation="vertical" variant="middle" flexItem />

        <Button
          {...buttonBaseProps}
          onClick={handlePDF}
          startIcon={<DownloadSharpIcon color="primary" />}
          sx={{ mx: 1 }}
        >
          Download PDF
        </Button>
      </GridToolbarContainer>
    );
  };

  return (
    <div style={{ height: '90%', width: '100%' }}>
      <Stack 
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
        sx={{ mb: 2 }}
      >
        <TextField
          label="Başlangıç Tarihi"
          id="startDate"
          type="date"
          size="small"
          defaultValue={minInputDate}
          value={minInputDate}
          onChange={handleChangeMinDate}
          InputLabelProps={{ shrink: true }} //label'ı oto. üste alır
          // InputProps={{
          //   inputProps:{ max: defaultDate }
          // }}
          sx={{ minWidth: 170 }}
        />
        <TextField
          label="Bitiş Tarihi"
          id="endDate"
          type="date"
          size="small"
          defaultValue={defaultDate}
          value={defaultDate}
          onChange={handleChangeMaxDate}
          InputLabelProps={{ shrink: true }} //label'ı oto. üste alır
          // InputProps={{
          //   inputProps:{ max: defaultDate }
          // }}
          sx={{ minWidth: 170 }}
        />
        <TextField
          id="customer"
          label="Müşteri"
          value={musteri}
          onChange={handleChangeMusteri}
          size="small"
          sx={{ minWidth: 100 }}
        />
        <TextField
          id="partner"
          label="Partner"
          value={partner}
          onChange={handleChangePartner}
          size="small"
          sx={{ minWidth: 100 }}
        />
        <TextField
          id="proje"
          label="Proje"
          value={proje}
          onChange={handleChangeProje}
          size="small"
          sx={{ minWidth: 100 }}
        />
      
        {/* <FormControl sx={{ mb: 2, mr: 2 }}> */}
        <FormControl>
          {/* <InputLabel id="user-select-label">Danışman</InputLabel>
          <Select
            labelId="user-select-label"
            id="user-select"
            size="small"
            value={user}
            onChange={handleChangeUser}
            label="Danışman"
            // input={<OutlinedInput label="Danışman" />}
            sx={{ minWidth: 200 }}
          >
            {users}
          </Select> */}
          
          <TextField
            select
            id="user-select"
            label="Danışman"
            value={user}
            onChange={handleChangeUser}
            size="small"
            sx={{ minWidth: 200 }}
          >
            {users}
          </TextField>
        </FormControl>
        <Button 
          onClick={() => { 
            setLoading(true); 
            fetchData(); 
          }}
          variant="contained" 
          endIcon={<RefreshIcon />} 
          sx={{ minWidth: 150 }}
        >
          Getir
        </Button>
      </Stack>
      <DataGrid
        rows={rows}
        columns={columns}
        // pageSize={5}
        // rowsPerPageOptions={[5]}
        pageSize={pageSize}
        onPageSizeChange={(newPage) => setPageSize(newPage)}
        pagination
        components={{ Toolbar: CustomToolbar }}
        loading={loading}
      />
    </div>
  );
}
