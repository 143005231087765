import * as React from 'react';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PeopleIcon from '@mui/icons-material/People';
import BarChartIcon from '@mui/icons-material/BarChart';
import LayersIcon from '@mui/icons-material/Layers';
import AssignmentIcon from '@mui/icons-material/Assignment';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import GroupsIcon from '@mui/icons-material/Groups';
import PersonIcon from '@mui/icons-material/Person';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import SnippetFolderIcon from '@mui/icons-material/SnippetFolder';
import RestorePageIcon from '@mui/icons-material/RestorePage';
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
// import StarBorder from '@mui/icons-material/StarBorder';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import EventNoteIcon from '@mui/icons-material/EventNote';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import DateRangeIcon from '@mui/icons-material/DateRange';

import useAuth from '../authentication/useAuth';


export default function ListItemsContent() {

  const { auth, setAuth } = useAuth();

  const [selectedIndex, setSelectedIndex] = React.useState(window.location.pathname);
  const handleListItemClick = (event, path) => {
    setSelectedIndex(path);
  };

  const [open, setOpen] = React.useState(true);
  const handleClick = () => {
    setOpen(!open);
  };

  return (
  <React.Fragment>
    <ListItemButton
      component={Link} href="/"
      selected={selectedIndex === "/"}
      // onClick={(event) => handleListItemClick(event, "/")}
      >
      <ListItemIcon>
        <NoteAddIcon color="primary" />
      </ListItemIcon>
      <ListItemText primary="Aktivite Giriş" />
    </ListItemButton>
    <ListItemButton
      component={Link} href="/izinler"
      selected={selectedIndex === "/izinler"}
      // onClick={(event) => handleListItemClick(event, "/izinler")}
      >
      <ListItemIcon>
        <WorkHistoryIcon color="primary" />
      </ListItemIcon>
      <ListItemText primary="İzinler" />
    </ListItemButton>
    {/* <ListItemButton
      component={Link} href="/masraflar"
      selected={selectedIndex === "/masraflar"}
      // onClick={(event) => handleListItemClick(event, "/masraflar")}
      >
      <ListItemIcon>
        <AccountBalanceWalletIcon color="primary" />
      </ListItemIcon>
      <ListItemText primary="Masraflar" />
    </ListItemButton> */}
    {auth == 1 && 
    <React.Fragment>
    {/* <ListItemButton
      component={Link} href="/eski-aktiviteler"
      selected={selectedIndex === "/eski-aktiviteler"}
      // onClick={(event) => handleListItemClick(event, "/eski-aktiviteler")}
      >
      <ListItemIcon>
        <RestorePageIcon color="primary" />
      </ListItemIcon>
      <ListItemText primary="Eski Aktiviteler" />
    </ListItemButton> */}
    <ListItemButton
      component={Link} 
      href="/proje-giris"
      selected={selectedIndex === "/proje-giris"}
      // onClick={(event) => handleListItemClick(event, "/proje-giris")}
      >
      <ListItemIcon>
        <CreateNewFolderIcon color="primary" />
      </ListItemIcon>
      <ListItemText primary="Proje Tanımlama" />
    </ListItemButton>
    {/* <ListItemButton
      component={Link} 
      href="/proje-toplu-giris"
      selected={selectedIndex === "/proje-toplu-giris"}
      // onClick={(event) => handleListItemClick(event, "/proje-toplu-giris")}
      >
      <ListItemIcon>
        <CreateNewFolderIcon color="primary" />
      </ListItemIcon>
      <ListItemText primary="Proje TOPLU Giriş" />
    </ListItemButton> */}
    <ListItemButton
      component={Link} 
      href="/musteriler"
      selected={selectedIndex === "/musteriler"}
      // onClick={(event) => handleListItemClick(event, "/musteriler")}
      >
      <ListItemIcon>
        <BusinessCenterIcon color="primary" />
      </ListItemIcon>
      <ListItemText primary="Müşteriler" />
    </ListItemButton>
    <ListItemButton
      component={Link} 
      href="/partnerler"
      selected={selectedIndex === "/partnerler"}
      // onClick={(event) => handleListItemClick(event, "/partnerler")}
      >
      <ListItemIcon>
        <GroupsIcon color="primary" />
      </ListItemIcon>
      <ListItemText primary="Partnerler" />
    </ListItemButton>
    <ListItemButton
      component={Link} href="/kullanicilar"
      selected={selectedIndex === "/kullanicilar"}
      // onClick={(event) => handleListItemClick(event, "/kullanicilar")}
      >
      <ListItemIcon>
        <PeopleIcon color="primary" />
      </ListItemIcon>
      <ListItemText primary="Kullanıcılar" />
    </ListItemButton>
    {/* <ListItemButton
      selected={selectedIndex === 3}
      onClick={(event) => handleListItemClick(event, 3)}>
      <ListItemIcon>
        <BarChartIcon />
      </ListItemIcon>
      <ListItemText primary="Raporlar" />
    </ListItemButton> */}
    {/* <ListItemButton>
      <ListItemIcon>
        <LayersIcon />
      </ListItemIcon>
      <ListItemText primary="Integrations" />
    </ListItemButton> */}
    <Divider sx={{ my: 1 }} />
    {/* <ListItemButton onClick={() => {setOpen(!open);}}> */}
    <ListItemButton 
      // component={Link} href="/rapor"
      selected={selectedIndex === "/rapor"}
      onClick={handleClick}>
      <ListItemIcon>
        <BarChartIcon color="secondary" />
      </ListItemIcon>
      <ListItemText primary="Raporlar" />
      {open ? <ExpandLess /> : <ExpandMore />}
    </ListItemButton>
    <Collapse in={open} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        <ListItemButton sx={{ pl: 4 }}
          component={Link} href="/rapor"
          onClick={(event) => handleListItemClick(event, "/rapor")}>
          <ListItemIcon>
            <EventNoteIcon color="secondary" />
          </ListItemIcon>
          <ListItemText primary="Aktivite Giriş" />
        </ListItemButton>
        <ListItemButton sx={{ pl: 4 }}
          component={Link} href="/izinler-rapor"
          onClick={(event) => handleListItemClick(event, "/izinler-rapor")}>
          <ListItemIcon>
            <CalendarMonthIcon color="secondary" />
          </ListItemIcon>
          <ListItemText primary="İzin Girişleri" />
        </ListItemButton>
        <ListItemButton sx={{ pl: 4 }}
          component={Link} href="/izin-sayilari"
          onClick={(event) => handleListItemClick(event, "/izin-sayilari")}>
          <ListItemIcon>
            <EventAvailableIcon color="secondary" />
          </ListItemIcon>
          <ListItemText primary="İzin Sayıları" />
        </ListItemButton>
        {/* <ListItemButton sx={{ pl: 4 }}
          component={Link} href="/masraflar"
          onClick={(event) => handleListItemClick(event, "/masraflar")}>
          <ListItemIcon>
            <DateRangeIcon color="secondary" />
          </ListItemIcon>
          <ListItemText primary="Masraflar" />
        </ListItemButton> */}
        

        {/* <ListItemButton sx={{ pl: 4 }}>
          <ListItemIcon>
            <StarBorder />
          </ListItemIcon>
          <ListItemText primary="Starred" />
        </ListItemButton> */}
      </List>
    </Collapse>
    </React.Fragment>
    }
  </React.Fragment>
  );
}