import './App.css';
import Dashboard from './dashboard/Dashboard';
import SignInSide from './authentication/SignInSide';
import useAuth from './authentication/useAuth';

function App() {
  const { auth, setAuth } = useAuth();
  // console.log("Auth: " + auth);

  if(!auth) {
    return <SignInSide setAuth={setAuth} />
  }

  return (
    <div className="App">
      <Dashboard />
    </div>
  );
}

export default App;
