import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import AddIcon from '@mui/icons-material/AddCircleOutline';

import axios from 'axios';

export default function DialogSelect({addRow}) {
  const [open, setOpen] = React.useState(false);

  const [textValue, setTextValue] = useState("");
  const onTextChange = (e) => setTextValue(e.target.value);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setOpen(false);
    }
  };

  const handleAdd = () => {
    const options = {
      headers: {
          'Content-Type': 'application/json',
      }
    };
    
    const data = { name: textValue };
    
    axios.post('https://aktivite-app.herokuapp.com/musteriler', data, options)
    .then(function (response) {
      // console.log(response);
      addRow({ id: response.data.message, name: textValue });
      // alert(response.data.message + " " + textValue + " başarıyla eklendi");
    })
    .catch(function (error) {
      console.log(error);
      alert(error);
    });

    setOpen(false);
    setTextValue("");
  };
  
  return (
    <div>
      <Button onClick={handleClickOpen} startIcon={<AddIcon />}>
        YENİ MÜŞTERİ GİRİŞİ
      </Button>
      <Dialog disableEscapeKeyDown open={open} onClose={handleClose}>
        <DialogTitle>Müşteri eklemek için bilgileri eksiksiz doldurunuz</DialogTitle>
        <DialogContent>
          <Box component="form" sx={{ display: 'grid', gridAutoFlow: 'row'}}>
            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <TextField
                required
                id="musteri-adi"
                label="Müşteri Adı"
                onChange={onTextChange}
                value={textValue}
              />
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>İptal</Button>
          <Button onClick={handleAdd}>EKLE</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}