import React, { useState, useEffect, useCallback } from 'react';
import { DataGrid, GridToolbarContainer } from '@mui/x-data-grid';
import Divider from '@mui/material/Divider';

import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import DownloadSharpIcon from '@mui/icons-material/DownloadSharp';
import { red } from '@mui/material/colors';

import Button from '@mui/material/Button';

import { utils, write, writeFile } from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import "../fonts/OpenSans-Regular";

import axios from 'axios';

import CustomerInputs from './CustomerInputs';

export default function DataTable() {

  // //DELETE ROW
  // const [open, setOpen] = useState(false);
  // const handleClickOpen = () => {
  //   selectedRows.length ? setOpen(true) : window.alert("Lütfen en az bir satır seçin")
  // };
  // const handleClose = () => {
  //   setOpen(false);
  // };
  // const handleDelete = () => {
  //   const selectedIDs = new Set(selectionModel);
  //   setData((r) => r.filter((x) => !selectedIDs.has(x.id)));
  //   console.log(selectedIDs);
  //   selectedIDs.forEach(id => {
  //     axios.delete(`https://aktivite-app.herokuapp.com/musteriler/${id}`)
  //     .then(function (response) {
  //       // console.log(response);
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //       alert(error);
  //     });
  //   });
  //   setOpen(false);
  // };
  
  // //ROW SELECTION CHECKBOX
  // const [selectedRows, setRowsData] = useState([])
  // const [selectionModel, setSelectionModel] = useState([]);

  //EXCEL
  const handleExcel = () => {
    const workSheet = utils.json_to_sheet(rows);
    const workBook = utils.book_new();
    utils.book_append_sheet(workBook, workSheet, "easyit-rapor");
    //Buffer
    let buf = write(workBook, { bookType:"xlsx", type:"buffer" });
    //Binary string
    write(workBook, { bookType:"xlsx", type:"binary" });
    //Download
    writeFile(workBook, "EasyIT-Oto-Rapor.xlsx");
  }

  //PDF
  const handlePDF = () => {
    const doc = new jsPDF();
    doc.text("EasyIT Rapor", 20, 10);
    doc.autoTable({
      theme: "grid",
      columns: columns.map(col => ({ ...col, dataKey: col.field })),
      body: rows,
      styles: {
        font: 'OpenSans-Regular',
        fontStyle: 'normal',
      }
    });
    doc.save('EasyIT-Oto-Rapor.pdf');
  }
  
  //TABLE ROW DATA
  const [rows, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const addRow = useCallback((row) => {
    setData((t) => [row, ...t]);
  }, [rows]);

  useEffect(() => {
    const fetchData = async () => {
      const res = await fetch(
        'https://aktivite-app.herokuapp.com/musteriler',
      );
      const json = await res.json();
      if(json.data)
        setData(json.data);
      setLoading(false);
    };
    fetchData();
  }, [setData]);

  const columns = [
    { field: 'id', headerAlign: 'center', headerName: 'Müşteri ID', width: 100, editable: false, align: "center" },
    { field: 'name', headerName: 'Müşteri Adı', width: 130, flex: 1, editable: true },
  ];


  const [pageSize, setPageSize] = React.useState(25);


  // const [editRowsModel, setEditRowsModel] = React.useState({});

  // const handleEditRowsModelChange = React.useCallback((model) => {
  //   setEditRowsModel(model);
  // }, []);


  const CustomToolbar = () => {

    const buttonBaseProps = {
      color: 'primary',
      size: 'small',
      // startIcon: <ExportIcon />,
    };
  
    return (
      <GridToolbarContainer>
        <Button
          {...buttonBaseProps}
          onClick={handleExcel}
          startIcon={<DownloadSharpIcon color="primary" />}
          sx={{ mx: 1 }}
        >
          Export to Excel
        </Button>

        <Divider orientation="vertical" variant="middle" flexItem />

        <Button
          {...buttonBaseProps}
          onClick={handlePDF}
          startIcon={<DownloadSharpIcon color="primary" />}
          sx={{ mx: 1 }}
        >
          Download PDF
        </Button>

        <Divider orientation="vertical" variant="middle" flexItem />
        
        {/* <div style={{
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap',
        }}>
          <Button
            {...buttonBaseProps}
            onClick={handleClickOpen}
            startIcon={<DeleteOutlinedIcon color={red[500]} />}
            sx={{ mx: 1, color: red[500] }}
          >
            Delete Row
          </Button>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Seçilen satırları silmek istediğinize emin misiniz?"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {selectedRows.map((item) => {
                  return <p>{item.id} - {item.name}</p>
                })}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>İptal</Button>
              <Button onClick={handleDelete}>SİL</Button>
            </DialogActions>
          </Dialog>
        </div> */}
      </GridToolbarContainer>
    );
  };

  return (
    <div style={{ height: '90%', width: '100%' }}>
      <CustomerInputs addRow={addRow}/>
      <DataGrid
        rows={rows}
        columns={columns}
        // pageSize={5}
        // rowsPerPageOptions={[5]}
        // checkboxSelection
        editMode="row"
        //Store Data from the row in another variable
        // onSelectionModelChange = {(id) => {
        //   setSelectionModel(id);
        //   const selectedIDs = new Set(id);
        //   const selectedRowData = rows.filter((row) =>
        //     selectedIDs.has(row.id)
        //   );
        //   setRowsData(selectedRowData)
        // }}
        // editRowsModel={editRowsModel}
        // onEditRowsModelChange={handleEditRowsModelChange}
        pageSize={pageSize}
        onPageSizeChange={(newPage) => setPageSize(newPage)}
        pagination
        components={{ Toolbar: CustomToolbar }}
        loading={loading}
      />
    </div>
  );
}
