import React, { useState, useEffect, useCallback } from 'react';
import { DataGrid, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarColumnsButton, GridToolbarFilterButton, useGridApiContext } from '@mui/x-data-grid';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import { IconButton } from '@mui/material';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import SimCardDownloadOutlinedIcon from '@mui/icons-material/SimCardDownloadOutlined';
import ExplicitOutlinedIcon from '@mui/icons-material/ExplicitOutlined';
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import DownloadSharpIcon from '@mui/icons-material/DownloadSharp';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { red, green } from '@mui/material/colors';
import Tooltip from '@mui/material/Tooltip';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';

import { utils, write, writeFile } from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import "../fonts/OpenSans-Regular";

import axios from 'axios';

import AktiviteInputs from './AktiviteInputs';

// async componentDidMount() {
  
//   db.collection('customers').get().then((snapshot) => {
//     const data2 = [];
//     snapshot.docs.forEach(doc => {
//       const customer = {
//         cid : doc.data().cid,
//         cname : doc.data().cname,
//         pname : doc.data().pname,
//         project : doc.data().project,
//         efor : doc.data().efor
//       };
//       data2.push(customer);
//     })
//     this.setState(prevState => {
//       return { customers: [...prevState.customers, ...data2] };
//     });
//   })

//   const activityData = db.collection('activities').get().then((snapshot) => {
//     const data = [];
//     snapshot.docs.forEach(doc => {
//       const activity = {
//         aid : doc.data().aid,
//         cid : doc.data().cid,
//         uid : doc.data().uid,
//         tarih : doc.data().tarih,
//         saat : doc.data().saat,
//         cname : doc.data().cname,
//         pname : doc.data().pname,
//         project : doc.data().project,
//         desc : doc.data().desc
//       };
//       activityData.push(activity);
//     })
// //     this.setState(prevState => {
// //       return { data: [...prevState.data, ...data] };
// //     });
//   });
//   console.log(activityData);
// // }

// const activitiesRef = db.collection('activities');
// const snapshot = await activitiesRef.get();
// snapshot.forEach(doc => {
//   console.log(doc.id, '=>', doc.data());
// });


  // const rows = fetch("https://aktivite-app.herokuapp.com/aktiviteler").then(res => res.json());
  // console.log(rows);


  // const rows = Aktiviteler;
  // const rows = Aktiviteler.map((item) => {
  //   const tarih = moment(item.date).format("dd.mm.yyyy hh:MM:ss");
  //   return [
  //     { customerName: item.customerName },
  //     { partnerName: item.partnerName },
  //     { projeType: item.projeType },
  //     { efor: item.efor },
  //     { date: tarih },
  //     { aciklama: item.aciklama },

  //   ]
  // });
// const rows = [
//   { id: 1, lastName: 'Snow', firstName: 'Jon', age: 35 },
//   { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 42 },
//   { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 45 },
//   { id: 4, lastName: 'Stark', firstName: 'Arya', age: 16 },
//   { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null },
//   { id: 6, lastName: 'Melisandre', firstName: null, age: 150 },
//   { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
//   { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36 },
//   { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65 },
// ];


function isOverflown(element) {
  return (
    element.scrollHeight > element.clientHeight ||
    element.scrollWidth > element.clientWidth
  );
}

const GridCellExpand = React.memo(function GridCellExpand(props) {
  const { width, value } = props;
  const wrapper = React.useRef(null);
  const cellDiv = React.useRef(null);
  const cellValue = React.useRef(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showFullCell, setShowFullCell] = React.useState(false);
  const [showPopper, setShowPopper] = React.useState(false);

  const handleMouseEnter = () => {
    const isCurrentlyOverflown = isOverflown(cellValue.current);
    setShowPopper(isCurrentlyOverflown);
    setAnchorEl(cellDiv.current);
    setShowFullCell(true);
  };

  const handleMouseLeave = () => {
    setShowFullCell(false);
  };

  React.useEffect(() => {
    if (!showFullCell) {
      return undefined;
    }

    function handleKeyDown(nativeEvent) {
      // IE11, Edge (prior to using Bink?) use 'Esc'
      if (nativeEvent.key === 'Escape' || nativeEvent.key === 'Esc') {
        setShowFullCell(false);
      }
    }

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [setShowFullCell, showFullCell]);

  return (
    <Box
      ref={wrapper}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      sx={{
        alignItems: 'center',
        lineHeight: '24px',
        width: 1,
        height: 1,
        position: 'relative',
        display: 'flex',
      }}
    >
      <Box
        ref={cellDiv}
        sx={{
          height: 1,
          width,
          display: 'block',
          position: 'absolute',
          top: 0,
        }}
      />
      <Box
        ref={cellValue}
        sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
      >
        {value}
      </Box>
      {showPopper && (
        <Popper
          open={showFullCell && anchorEl !== null}
          anchorEl={anchorEl}
          style={{ width, marginLeft: -17 }}
        >
          <Paper
            elevation={1}
            style={{ minHeight: wrapper.current.offsetHeight - 3 }}
          >
            <Typography variant="body2" style={{ padding: 8 }}>
              {value}
            </Typography>
          </Paper>
        </Popper>
      )}
    </Box>
  );
});

GridCellExpand.propTypes = {
  value: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
};

function renderCellExpand(params) {
  return (
    <GridCellExpand value={params.value || ''} width={params.colDef.computedWidth} />
  );
}

renderCellExpand.propTypes = {
  /**
   * The column of the row that the current cell belongs to.
   */
  colDef: PropTypes.object.isRequired,
  /**
   * The cell value, but if the column has valueGetter, use getValue.
   */
  value: PropTypes.string.isRequired,
};

export default function DataTable() {
  // //UPDATE ROW
  // const handleRowEditStop = (params, event) => {
  //   console.log(params)
  //   console.log(event)
  // }
  // const handleRowEditStart = (params, event) => {
  //   console.log(params)
  //   console.log(event)
  // }
  
  // const handleRowEditStop = React.useCallback(
  //   async (newRow) => {
      
  //     const data = { 
  //       efor: newRow.row.efor, 
  //       proje_id: newRow.row.proje_id, 
  //       aciklama: newRow.row.aciklama,
  //       // mod_date: unixDate,
  //       // mod_user_id: user
  //     };
    
  //     // await axios.put(`https://aktivite-app.herokuapp.com/aktiviteler/${id}`, data)
  //     // .then(function (response) {
  //     //   // console.log(response);
  //     // })
  //     // .catch(function (error) {
  //     //   console.log(error);
  //     //   alert(error);
  //     // });
  //     // Make the HTTP request to save in the backend
  //     // const response = await mutateRow(newRow);
  //     // setSnackbar({ children: 'User successfully saved', severity: 'success' });
  //     // return response;
  //     console.log("newRow")
  //     console.log(newRow)
  //   },
  //   // [mutateRow],
  //   [],
  // );

  //DELETE ROW
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    selectedRows.length ? setOpen(true) : window.alert("Lütfen en az bir satır seçin")
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleDelete = () => {
    const selectedIDs = new Set(selectionModel);
    setData((r) => r.filter((x) => !selectedIDs.has(x.id)));
    // console.log(selectedIDs);
    selectedIDs.forEach(async id => {
      await axios.delete(`https://aktivite-app.herokuapp.com/aktiviteler/${id}`)
      .then(function (response) {
        // console.log(response);
      })
      .catch(function (error) {
        console.log(error);
        alert(error);
      });
    });
    setOpen(false);
  };
  
  //ROW SELECTION CHECKBOX
  const [selectedRows, setRowsData] = useState([])
  const [selectionModel, setSelectionModel] = useState([]);

  //EXCEL
  const handleExcel = () => {
    //TÜM DATAYI İNDİRİYOR PAGINATION YAPMIYOR
    const newData = rows.map(row => {
      delete row.id;
      delete row.cid;
      delete row.pid;
      delete row.proje_id;
      const temp = {
        Tarih: new Date(row.date * 1000).toLocaleDateString(),
        Olusturan: row.consultant,
        Efor: row.efor,
        Musteri: row.customer,
        Partner: row.partner,
        Proje: row.proje,
        DuzenlendiMi: (row.is_modified ? 'EVET' : null),
        DuzenlemeTarih: (row.mod_date ? new Date(row.mod_date * 1000).toLocaleDateString() : null),
        Duzenleyen: row.mod_user,
        Aciklama: row.aciklama
      }
      return temp;
    });
    const workSheet = utils.json_to_sheet(newData);
    const workBook = utils.book_new();
    utils.book_append_sheet(workBook, workSheet, "easyit-rapor");
    //Buffer
    let buf = write(workBook, { bookType:"xlsx", type:"buffer" });
    //Binary string
    write(workBook, { bookType:"xlsx", type:"binary" });
    //Download
    writeFile(workBook, "EasyIT-Oto-Rapor.xlsx");
  }

  //PDF
  const handlePDF = () => {
    const newData = rows.map(row => {
      const temp = {
        date: new Date(row.date * 1000).toLocaleDateString(),
        consultant: row.consultant,
        efor: row.efor,
        customer: row.customer,
        partner: row.partner,
        proje: row.proje,
        is_modified: (row.is_modified ? 'EVET' : null),
        mod_date: (row.mod_date ? new Date(row.mod_date * 1000).toLocaleDateString() : null),
        mod_user: row.mod_user,
        aciklama: row.aciklama
      }
      return temp;
    });
    const doc = new jsPDF();
    doc.text("EasyIT Rapor", 20, 10);
    doc.autoTable({
      theme: "grid",
      columns: columns.map(col => ({ ...col, dataKey: col.field })),
      body: newData,
      styles: {
        font: 'OpenSans-Regular',
        fontStyle: 'normal',
      }
    });
    doc.save('EasyIT-Oto-Rapor.pdf');
  }

  //TABLE ROW DATA
  const [rows, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const addRow = useCallback((row) => {
    setData((t) => [row, ...t]);
  }, [rows]);

  // const [modified, setModified] = useState("");
  // const handleChangeModified = (event) => {
  //   setModified(event.target.value);
  // };

  //UNIX TIMESTAMP
  // const maxDate = Math.floor(new Date().getTime() / 1000);
  // const tempDate = new Date();
  // tempDate.setDate(1);
  // tempDate.setHours(0, 0, 0, 0);
  // const minDate = Math.floor(tempDate.getTime() / 1000);
  const lastMonth = Math.floor(new Date(new Date(new Date(new Date().setMonth(new Date().getMonth() - 1)).setDate(1)).setHours(0, 0, 0, 0)).getTime() / 1000)
  const thisMonth = Math.floor(new Date().getTime() / 1000);
  const [maxDate, setMaxDate] = useState(Math.floor(new Date().getTime() / 1000));
  const [minDate, setMinDate] = useState(new Date().getDate() < 7 
    ? Math.floor(new Date(new Date(new Date(new Date().setMonth(new Date().getMonth() - 1)).setDate(1)).setHours(0, 0, 0, 0)).getTime() / 1000) // ilk 7 gün
    : Math.floor(new Date(new Date(new Date().setDate(1)).setHours(0, 0, 0, 0)).getTime() / 1000));
  //   // 31 marttan geri dönünce 31 şubat yani 3 mart yapmaması için kod
    // if (minDate.getMonth() == new Date().getMonth()) 
    //   minDate.setDate(0);

  // console.log(minDate);
  // console.log(maxDate);

  //ayarlar ekranından aktivite giriş kısıtlaması girilmiş ise:
  const id = 1;
  useEffect(() => {
    const fetchData = async () => {
      const res = await fetch(
        `https://aktivite-app.herokuapp.com/ayarlar?id=${id}`,
      );
      const json = await res.json();
      // console.log(json.data)
      if(json.data && !(json.data[0].minDate == 0)){
        setMinDate(json.data[0].minDate);
      }
      if(json.data && !(json.data[0].maxDate == 0)){
        setMaxDate(json.data[0].maxDate);
      }
    };
    fetchData();
  }, []);

  //ELEYEN USER ID
  const user = sessionStorage.getItem('user');
  // const [user, setUser] = useState(106);
  // const handleChangeUser = (event) => {
  //   setUser(event.target.value);
  // };

  // const [proje, setProje] = useState("");
  // const handleChangeProje = (event) => {
  //   setProje(event.target.value);
  // };

  useEffect(() => {
    const fetchData = async () => {
      const res = await fetch(
        // `https://aktivite-app.herokuapp.com/aktiviteler?`,
        `https://aktivite-app.herokuapp.com/aktiviteler?startDate=${lastMonth}&endDate=${thisMonth}&uid=${user}`,
      );
      const json = await res.json();
      if(json.data){
        // for (var i=0; i<json.data.length; i++){ //UNIX Timestamp
        //   json.data[i].date = new Date(json.data[i].date * 1000).toLocaleDateString();
        //   if(json.data[i].mod_date)
        //     json.data[i].mod_date = new Date(json.data[i].mod_date * 1000).toLocaleDateString();
        //   if(json.data[i].is_modified)
        //     json.data[i].is_modified = "EVET";
        // }
        setData(json.data);
      }
      setLoading(false);
    };
    fetchData();
  }, [user, minDate, maxDate]);
  
  const columns = [
    // { field: 'id', headerName: 'ID', width: 70 },
    {
      field: 'date',
      headerAlign: 'center',
      headerName: 'Tarih',
      type: 'date',
      width: 130,
      editable: false,
      align: 'center',
      valueFormatter: (params) => {
        if (params.value == null) { return ''; }
        const valueFormatted = new Date(params.value * 1000).toLocaleDateString();
        return `${valueFormatted}`;
      }
    },
    { field: 'customer', headerName: 'Müşteri', width: 130, editable: false, renderCell: renderCellExpand },
    { field: 'partner', headerName: 'Partner', width: 130, editable: false, renderCell: renderCellExpand },
    { field: 'proje', headerName: 'Proje', width: 130, editable: false, renderCell: renderCellExpand },
    {
      field: 'efor',
      headerAlign: 'center',
      headerName: 'Efor(Saat)',
      type: 'number',
      width: 90,
      editable: false,
      align: 'center'
    },
    // { field: 'consultant', headerName: 'Ekleyen', width: 130, editable: false, renderCell: renderCellExpand },
    { 
      field: 'is_modified', 
      headerAlign: 'center', 
      headerName: 'Güncellendi Mi', 
      width: 130, 
      editable: false, 
      align: 'center',
      valueFormatter: (params) => {
        if (params.value) { return "EVET"; }
        else { return ''; }
      }
    },
    {
      field: 'mod_date',
      headerAlign: 'center',
      headerName: 'Gün. Tarihi',
      description: 'Güncelleme Tarihi',
      type: 'date',
      width: 130,
      editable: false,
      align: 'center',
      valueFormatter: (params) => {
        if (params.value == null) { return ''; }
        const valueFormatted = new Date(params.value * 1000).toLocaleDateString();
        return `${valueFormatted}`;
      }
    },
    { field: 'mod_user', headerName: 'Güncelleyen', width: 130, editable: false, renderCell: renderCellExpand },
    {
      field: 'aciklama',
      headerName: 'Açıklama',
      description: 'Proje Açıklaması',
      sortable: false,
      flex: 1,
      editable: false,
      renderCell: renderCellExpand
    }
  ];


  const [pageSize, setPageSize] = React.useState(25);


  // const [editRowsModel, setEditRowsModel] = React.useState({});

  // const handleEditRowsModelChange = React.useCallback((model) => {
  //   setEditRowsModel(model);
  // }, []);

    
  const [openEdit, setOpenEdit] = useState(false);
  const handleClickEdit = () => {
    selectedRows.length == 1 ? setOpenEdit(true) : window.alert("Lütfen 1 satır seçin")
  };
  function CustomEditRow(){
    const handleCloseEdit = (event, reason) => {
      if (reason !== 'backdropClick') {
        setOpenEdit(false);
      }
    };
    const handleEdit = () => {
      let isError = false;
      if(efor < 1 || !(efor)){
        isError = true;
        alert("Geçerli bir efor giriniz!");
      } 
      if(!(proje)){
        isError = true;
        alert("Geçerli bir proje seçiniz!");
      } 
      if(!(aciklama)){
        isError = true;
        alert("Geçerli bir açıklama giriniz!");
      } 
      if(!(user)){
        isError = true;
        alert("Kullanıcı tanınmadı, yöneticiye ulaşınız.");
      } 
      if(!isError){
        handleEdit2();
      }
    }
    const handleEdit2 = async () => {         
      const id = selectedRows[0].id;  
      const data = { 
        efor: efor, 
        proje_id: proje, 
        aciklama: aciklama, 
        mod_date: unixDate, 
        mod_user_id: user 
      };
      
      await axios.put(`https://aktivite-app.herokuapp.com/aktiviteler/${id}`, data)
      .then(function (response) {
        // console.log(response);

        const newRow = {  
          id: selectedRows[0].id, 
          date: selectedRows[0].date,
          consultant: projeFiltered[0].consultant,
          efor: efor, 
          cid: customer, 
          customer: customerArray[customer], 
          pid: projeFiltered[0].pid, 
          partner: projeFiltered[0].partner, 
          proje_id: projeFiltered.find( arr => arr['id'] === proje ).id, 
          proje: projeFiltered.find( arr => arr['id'] === proje ).proje, 
          aciklama: aciklama,
          is_modified: 'EVET',
          mod_date: unixDate,
          mod_user: 'Siz'
        };
        const selectedIDs = new Set(selectionModel);
        setData((r) => r.filter((x) => !selectedIDs.has(x.id)));
        setData((t) => [newRow, ...t]);
      })
      .catch(function (error) {
        console.log(error);
        alert(error);
      });
  
      setOpenEdit(false);
    };
    
    // console.log(selectedRows);
    // console.log(proje);
    // console.log(customerArray);
    // console.log(projeFiltered);
    // console.log(projeFiltered.find( arr => arr['id'] === proje ));
    // console.log(projeFiltered.find( arr => arr['id'] === proje ).proje);
    
    const [customer, setCustomer] = React.useState(selectedRows[0] ? selectedRows[0].cid : null);
    const [partner, setPartner] = React.useState(selectedRows[0] ? selectedRows[0].pid : null);
    const [proje, setProje] = React.useState(selectedRows[0] ? selectedRows[0].proje_id : null);
    const [efor, setEfor] = React.useState(selectedRows[0] ? selectedRows[0].efor : null);
    const [aciklama, setAciklama] = React.useState(selectedRows[0] ? selectedRows[0].aciklama : null);
  
    let partners = null;
    let projeler = null;
    
    // const selected = new Set(selectedRows);
    // console.log(selected);

    const [customerArray, setCustomerArray] = useState([]);
    
    const [projeArray, setProjeArray] = useState([]);
    useEffect(() => {
      const fetchData = async () => {
        const res = await fetch(
          'https://aktivite-app.herokuapp.com/projeler',
        );
        const json = await res.json();
        
        var lookup = {};
        var items = json.data;
        var result = {};
  
        for (var item, i = 0; item = items[i++];) {
          var name = item.customer;
          var id = item.cid;
  
          if (!(name in lookup)) {
            lookup[name] = 1;
            result[id] = name;
            // result.push(name);
          }
        }
        // console.log(result)
        setCustomerArray(result);
        // setPartnerArray(result);
        setProjeArray(json.data);
  
      };
      fetchData();
    }, []);
    let customers = Object.entries(customerArray).map(([key, value]) => (<MenuItem value={key}>{value}</MenuItem>));
    const filtered = projeArray.filter(item => item.cid == customer); //seçilen customer için tüm ihtimaller
    const partnerFiltered = filtered.filter( (item, index) => index === filtered.findIndex( element => element.pid === item.pid)); //seçilen customer için distinct partners
    const projeFiltered = projeArray.filter(item => item.cid == customer && item.pid == partner); // seçilen customer+partner ikilisini içeren projeler
  
    partners = partnerFiltered.map((item) => (<MenuItem value={item.pid}>{item.partner}</MenuItem>));
    projeler = projeFiltered.map((item) => (<MenuItem value={item.id}>{item.proje}</MenuItem>));
    
    const handleChangeEfor = (event) => {
      setEfor(Number(event.target.value) || '');
    };
  
    const handleChangeAciklama = (event) => {
      setAciklama(event.target.value);
    };

    const [unixDate, setUnixDate] = useState(Math.floor(new Date(new Date().setHours(0, 0, 0, 0)).getTime() / 1000));
    
    const handleChangeCustomers = (event) => {
      setCustomer(event.target.value);
      setPartner("");
      setProje();
    };
  
    const handleChangePartner = (event) => {
      setPartner(event.target.value);
      setProje();
    };
  
    const handleChangeProje = (event) => {
      setProje(event.target.value);
    };
  
    return (
      <Dialog disableEscapeKeyDown open={openEdit} onClose={handleCloseEdit}>
      <DialogTitle>Aktivite Girişinizi aşağıdan düzenleyebilirsiniz:</DialogTitle>
      <DialogContent>
        <Box component="form" sx={{ display: 'grid', gridAutoFlow: 'row', gridTemplateColumns: 'repeat(6, 1fr)', gridTemplateRows: 'repeat(3, 1fr)', }}>
          <FormControl sx={{ m: 1, gridRow: '1', gridColumn: 'span 6' }}>
            <TextField
              label="Efor"
              id="efor"
              type="number"
              value={efor}
              onChange={handleChangeEfor}
              // sx={{ m: 1, width: '25ch' }}
              InputProps={{
                // inputMode: 'numeric', pattern: '[0-9]*',
                inputProps: { min: "0" },
                endAdornment: <InputAdornment position="end">Saat</InputAdornment>
              }}
            />
          </FormControl>
          <FormControl sx={{ m: 1, gridRow: '2', gridColumn: 'span 2' }}>
            <InputLabel htmlFor="customer-select-label">Müşteriler</InputLabel>
            <Select
              labelId="customer-select-label"
              id="customer-select"
              value={customer}
              onChange={handleChangeCustomers}
              input={<OutlinedInput label="Müşteriler" />}
            >
              {customers}
            </Select>
          </FormControl>
          <FormControl sx={{ m: 1, gridRow: '2', gridColumn: 'span 2' }}>
            <InputLabel id="partner-select-label">Partnerler</InputLabel>
            <Select
              labelId="partner-select-label"
              id="partner-select"
              value={partner}
              onChange={handleChangePartner}
              input={<OutlinedInput label="Partnerler" />}
            >
              {partners}
            </Select>
          </FormControl>
          <FormControl sx={{ m: 1, gridRow: '2', gridColumn: 'span 2' }}>
            <InputLabel id="proje-select-label">Proje</InputLabel>
            <Select
              labelId="proje-select-label"
              id="proje-select"
              value={proje}
              onChange={handleChangeProje}
              input={<OutlinedInput label="Proje" />}
            >
              {projeler}
            </Select>
          </FormControl>
          <FormControl sx={{ m: 1, gridRow: '3', gridColumn: 'span 6' }}>
            <TextField
              id="outlined-multiline-flexible"
              label="Açıklama"
              multiline
              // maxRows={4}
              // Rows={2}
              value={aciklama}
              onChange={handleChangeAciklama}
            />
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseEdit}>İptal</Button>
        <Button onClick={handleEdit}>Düzenle</Button>
      </DialogActions>
    </Dialog>
    )
  }
  
  const CustomToolbar = () => {

    const buttonBaseProps = {
      color: 'primary',
      size: 'small',
      // startIcon: <ExportIcon />,
    };
  
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <Divider orientation="vertical" variant="middle" flexItem sx={{ mx: 1 }} />
        <GridToolbarFilterButton />
        <Divider orientation="vertical" variant="middle" flexItem sx={{ mx: 1 }} />
        <GridToolbarDensitySelector />
        <Divider orientation="vertical" variant="middle" flexItem sx={{ mx: 1 }} />


        <Button
          {...buttonBaseProps}
          onClick={handleExcel}
          startIcon={<DownloadSharpIcon color="primary" />}
        >
          Excel
        </Button>

        <Divider orientation="vertical" variant="middle" flexItem sx={{ mx: 1 }} />

        <Button
          {...buttonBaseProps}
          onClick={handlePDF}
          startIcon={<DownloadSharpIcon color="primary" />}
        >
          PDF
        </Button>


        <Divider orientation="vertical" variant="middle" flexItem sx={{ mx: 1 }} />

        <div style={{
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap',
        }}>
          <Button
            {...buttonBaseProps}
            onClick={handleClickEdit}
            startIcon={<EditOutlinedIcon color={green[500]} />}
            sx={{ color: green[500] }}
          >
            Düzenle
          </Button>
          <CustomEditRow />
        </div>

        <Divider orientation="vertical" variant="middle" flexItem sx={{ mx: 1 }} />
        
        <div style={{
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap',
        }}>
          <Button
            {...buttonBaseProps}
            onClick={handleClickOpen}
            startIcon={<DeleteOutlinedIcon color={red[500]} />}
            sx={{ color: red[500] }}
          >
            Sil
          </Button>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Seçilen satırları silmek istediğinize emin misiniz?"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {selectedRows.map((item) => {
                  return <p>{new Date(item.date * 1000).toLocaleDateString()} - {item.customer} {item.partner} {item.proje}</p>
                })}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>İptal</Button>
              <Button onClick={handleDelete}>SİL</Button>
            </DialogActions>
          </Dialog>
        </div>
      </GridToolbarContainer>
    );
  };

  return (
    <div style={{ height: '90%', width: '100%' }}>
      <AktiviteInputs addRow={addRow}/>
      <DataGrid
        rows={rows}
        columns={columns}
        // pageSize={5}
        // rowsPerPageOptions={[5]}
        checkboxSelection
        // editMode="row"
        //Store Data from the row in another variable
        onSelectionModelChange = {(id) => {
          setSelectionModel(id);
          const selectedIDs = new Set(id);
          const selectedRowData = rows.filter((row) =>
            selectedIDs.has(row.id)
          );
          setRowsData(selectedRowData)
        }}
        // editRowsModel={editRowsModel}
        // onEditRowsModelChange={handleEditRowsModelChange}
        // isCellEditable={(params) => params.row.date < new Date()}
        pageSize={pageSize}
        onPageSizeChange={(newPage) => setPageSize(newPage)}
        pagination
        components={{ Toolbar: CustomToolbar }}
        loading={loading}
        // onRowEditStop={handleRowEditStop}
        // onRowEditStart={handleRowEditStart}
      />
      {/* <Typography sx={{ mx: 1, mt: 1, fontWeight: 'bold' }} variant="caption" display="block" gutterBottom align='left'>
        Düzenleme yapmak için satırlara çift tıklayın. <br />
        Düzenlemeyi bitirmek için ESC ya da Enter tuşunu kullanın.
      </Typography> */}
    </div>
  );
}
