import React, { useState, useEffect, useCallback } from 'react';
import { DataGrid, GridToolbarContainer } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';

import SendIcon from '@mui/icons-material/Send';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import DownloadSharpIcon from '@mui/icons-material/DownloadSharp';
import { red, green } from '@mui/material/colors';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { utils, write, writeFile } from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import "../fonts/OpenSans-Regular";

import axios from 'axios';

export default function IzinlerRapor() {
  
  //DELETE ROW
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    selectedRows.length ? setOpen(true) : window.alert("Lütfen en az bir satır seçin")
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleDelete = () => {

    const selectedIDs = new Set(selectionModel);
    setData((r) => r.filter((x) => !selectedIDs.has(x.id)));
    selectedIDs.forEach(async id => {
      const findSelected = selectedRows.find(item => item.id == id);
      const day = 1 + ((findSelected.enddate - findSelected.startdate) / (24 * 60 * 60));
      await axios.delete(`https://aktivite-app.herokuapp.com/izinler/${id}`)
      .then(function (response) {
        // console.log(response);
      })
      .then(async function () {
        const data2 = { consultant: findSelected.cid, day: day, type: "add" };
        await axios.patch(`https://aktivite-app.herokuapp.com/izinsayilari`, data2)
        .then(function (response) {
          // console.log(response);
        })
        .catch(function (error) {
          console.log(error);
          alert(error);
        });
      })
      .catch(function (error) {
        console.log(error);
        alert(error);
      });
    });
    setOpen(false);
  };
  
  //ROW SELECTION CHECKBOX
  const [selectedRows, setRowsData] = useState([])
  const [selectionModel, setSelectionModel] = useState([]);

  //EXCEL
  const handleExcel = () => {
    //TÜM DATAYI İNDİRİYOR PAGINATION YAPMIYOR
    const newData = rows.map(row => {
      delete row.id;
      const temp = {
        Baslangic: new Date(row.startdate * 1000).toLocaleDateString(),
        Bitis: new Date(row.enddate * 1000).toLocaleDateString(),
        Danisman: row.consultant,
        Aciklama: row.aciklama
      }
      return temp;
    });
    const workSheet = utils.json_to_sheet(newData);
    const workBook = utils.book_new();
    utils.book_append_sheet(workBook, workSheet, "easyit-rapor");
    //Buffer
    let buf = write(workBook, { bookType:"xlsx", type:"buffer" });
    //Binary string
    write(workBook, { bookType:"xlsx", type:"binary" });
    //Download
    writeFile(workBook, "EasyIT-Oto-Rapor.xlsx");
  }

  //PDF
  const handlePDF = () => {
    const newData = rows.map(row => {
      const temp = {
        startdate: new Date(row.startdate * 1000).toLocaleDateString(),
        enddate: new Date(row.enddate * 1000).toLocaleDateString(),
        consultant: row.consultant,
        aciklama: row.aciklama
      }
      return temp;
    });
    const doc = new jsPDF();
    doc.text("EasyIT Rapor", 20, 10);
    doc.autoTable({
      theme: "grid",
      columns: columns.map(col => ({ ...col, dataKey: col.field })),
      body: newData,
      styles: {
        font: 'OpenSans-Regular',
        fontStyle: 'normal',
      }
    });
    doc.save('EasyIT-Oto-Rapor.pdf');
  }

  //TABLE ROW DATA
  const [rows, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const [user, setUser] = useState(null);
  const handleChangeUser = (event) => {
    setUser(event.target.value);
  };

  const [startDate, setStartDate] = useState(new Date(new Date(new Date().setDate(1)).setHours(3, 0, 0, 0)).toISOString().split('T')[0]);
  // const [endDate, setEndDate] = useState(new Date(new Date().setHours(3, 0, 0, 0)).toISOString().split('T')[0]);
  const [endDate, setEndDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth()+1, 0, 3, 0, 0, 0).toISOString().split('T')[0]);
  const [unixStartDate, setUnixStartDate] = useState(Math.floor(new Date(new Date(new Date().setDate(1)).setHours(3, 0, 0, 0)).getTime() / 1000));
  // const [unixEndDate, setUnixEndDate] = useState(Math.floor(new Date(new Date().setHours(0, 0, 0, 0)).getTime() / 1000));
  const [unixEndDate, setUnixEndDate] = useState(Math.floor(new Date(new Date().getFullYear(), new Date().getMonth()+1, 0, 3, 0, 0, 0).getTime() / 1000));
  
  const handleChangeStartDate = (event) => {
    setStartDate(new Date(event.target.value).toISOString().split('T')[0]);
    setUnixStartDate(Math.floor(new Date(event.target.value).getTime() / 1000) - 10800); //+3GMT için sn çıkarma işlemi
  };

  const handleChangeEndDate = (event) => {
    setEndDate(new Date(event.target.value).toISOString().split('T')[0]);
    setUnixEndDate(Math.floor(new Date(event.target.value).getTime() / 1000) - 10800); //+3GMT için sn çıkarma işlemi
  };

  const fetchData = async () => {
    const res = await fetch(
        `https://aktivite-app.herokuapp.com/izinler?startDate=${unixStartDate}&endDate=${unixEndDate}&uid=${user}`,
    );
    const json = await res.json();
    if(json.data){
      setData(json.data);
    }
    setLoading(false);
  };
  useEffect(() => {
    fetchData();
  }, []);
  
  const columns = [
    // { field: 'id', headerName: 'ID', width: 70 },
    {
      field: 'startdate',
      headerAlign: 'center',
      headerName: 'Başlangıç Tarihi',
      type: 'date',
      minWidth: 130,
      flex: 1,
      editable: false,
      align: 'center',
      valueFormatter: (params) => {
        if (params.value == null) { return ''; }
        const valueFormatted = new Date(params.value * 1000).toLocaleDateString();
        return `${valueFormatted}`;
      }
    },
    {
      field: 'enddate',
      headerAlign: 'center',
      headerName: 'Bitiş Tarihi',
      type: 'date',
      minWidth: 130,
      flex: 1,
      editable: false,
      align: 'center',
      valueFormatter: (params) => {
        if (params.value == null) { return ''; }
        const valueFormatted = new Date(params.value * 1000).toLocaleDateString();
        return `${valueFormatted}`;
      }
    },
    { field: 'consultant', headerName: 'Danışman', width: 130, flex: 1, editable: false },
    {
      field: 'aciklama',
      headerName: 'Açıklama',
      description: 'Açıklama',
      minWidth: 200,
      flex: 3,
      sortable: false,
      editable: false,
    }
  ];

  const [userArray, setUserArray] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const res = await fetch(
        `https://aktivite-app.herokuapp.com/kullanicilar?sort=ad`,
      );
      const json = await res.json();
      setUserArray(json.data);
    };
    fetchData();
  }, []);
  let users = userArray.map((item, i) => (<MenuItem value={item.id}>{item.ad}</MenuItem>));

  const [pageSize, setPageSize] = React.useState(25);

  const CustomToolbar = () => {
    const buttonBaseProps = {
      color: 'primary',
      size: 'small',
      // startIcon: <ExportIcon />,
    };
  
    return (
      <GridToolbarContainer>
        <Button
          {...buttonBaseProps}
          onClick={handleExcel}
          startIcon={<DownloadSharpIcon color="primary" />}
          sx={{ mx: 1 }}
        >
          Export to Excel
        </Button>

        <Divider orientation="vertical" variant="middle" flexItem />

        <Button
          {...buttonBaseProps}
          onClick={handlePDF}
          startIcon={<DownloadSharpIcon color="primary" />}
          sx={{ mx: 1 }}
        >
          Download PDF
        </Button>

        <Divider orientation="vertical" variant="middle" flexItem sx={{ mx: 1 }} />
        
        <div style={{
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap',
        }}>
          <Button
            {...buttonBaseProps}
            onClick={handleClickOpen}
            startIcon={<DeleteOutlinedIcon color={red[500]} />}
            sx={{ color: red[500] }}
          >
            Sil
          </Button>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Seçilen satırları silmek istediğinize emin misiniz?"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {selectedRows.map((item) => {
                  return <p>{new Date(item.startdate * 1000).toLocaleDateString()} - {new Date(item.enddate * 1000).toLocaleDateString()} : {item.consultant}</p>
                })}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>İptal</Button>
              <Button onClick={handleDelete}>SİL</Button>
            </DialogActions>
          </Dialog>
        </div>
      </GridToolbarContainer>
    );
  };

  return (
    <div style={{ height: '90%', width: '100%' }}>
      <Stack 
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        sx={{ mb: 2 }}
      >
        <TextField
          label="Başlangıç Tarihi"
          id="startDate"
          type="date"
          size="small"
          defaultValue={startDate}
          value={startDate}
          onChange={handleChangeStartDate}
          InputLabelProps={{ shrink: true }} //label'ı oto. üste alır
          // sx={{ minWidth: 200 }}
          sx={{ flex: 1 }}
        />
        <TextField
          label="Bitiş Tarihi"
          id="endDate"
          type="date"
          size="small"
          defaultValue={endDate}
          value={endDate}
          onChange={handleChangeEndDate}
          InputLabelProps={{ shrink: true }} //label'ı oto. üste alır
          // sx={{ minWidth: 200 }}
          sx={{ flex: 1 }}
        />
        <TextField
          select
          id="user-select"
          label="Danışman"
          value={user}
          onChange={handleChangeUser}
          size="small"
          // sx={{ minWidth: 200 }}
          sx={{ flex: 1 }}
        >
          {users}
        </TextField>
        <Button 
          onClick={() => { 
            setUser(null); 
            setStartDate(new Date(new Date(new Date().setDate(1)).setHours(3, 0, 0, 0)).toISOString().split('T')[0]);
            setEndDate(new Date(new Date().setHours(3, 0, 0, 0)).toISOString().split('T')[0]);
          }}
          variant="contained" 
          endIcon={<SettingsBackupRestoreIcon />} 
          // sx={{ minWidth: 150 }}
          sx={{ flex: 1 }}
        >
          Sıfırla
        </Button>
        <Button 
          onClick={() => { 
            setLoading(true); 
            fetchData(); 
          }}
          variant="contained" 
          endIcon={<SendIcon />} 
          // sx={{ minWidth: 150 }}
          sx={{ flex: 1 }}
        >
          Getir
        </Button>
      </Stack>
      <DataGrid
        rows={rows}
        columns={columns}
        checkboxSelection
        //Store Data from the row in another variable
        onSelectionModelChange = {(id) => {
          setSelectionModel(id);
          const selectedIDs = new Set(id);
          const selectedRowData = rows.filter((row) =>
            selectedIDs.has(row.id)
          );
          setRowsData(selectedRowData)
        }}
        pageSize={pageSize}
        onPageSizeChange={(newPage) => setPageSize(newPage)}
        pagination
        components={{ Toolbar: CustomToolbar }}
        loading={loading}
      />
    </div>
  );
}
