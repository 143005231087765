import React, { useEffect } from 'react';
import TextField from '@mui/material/TextField';
import SaveIcon from '@mui/icons-material/Save';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import { deepOrange } from '@mui/material/colors';
import Box from '@mui/material/Box';


import axios from 'axios';

export default function Ayarlar() {

  const uid = sessionStorage.getItem('user');
  const [name, setName] = React.useState("");
  const [mail, setMail] = React.useState("");
  const [pass, setPass] = React.useState("");
  const handleChangePass = (event) => { setPass(event.target.value); };

  useEffect(() => {
    const fetchData = async () => {
      const res = await fetch(
        `https://aktivite-app.herokuapp.com/kullanicilar?id=${uid}`,
      );
      const json = await res.json();
      // console.log(json.data)
      setName(json.data[0].ad);
      setMail(json.data[0].email);
      // console.log(json.data[0].ad)
      // console.log(json.data[0].email)
    };
    fetchData();
  }, []);

  const handleSavePass = async () => {
    const data = { password: pass };

    await axios.put(`https://aktivite-app.herokuapp.com/kullanicilar/${uid}`, data)
    .then(function (response) {
      // console.log(response);
    })
    .catch(function (error) {
      console.log(error);
      alert(error);
    });
  };
    
  return (
    // <div style={{ height: '90%', width: '100%' }}>
    <div style={{ width: '100%' }}>
      <Typography variant="h5" align="left" gutterBottom component="div" sx={{ m: 2 }}>
        Kullanıcı Ayarları
      </Typography>
      <Stack direction="row" spacing={2}>
        <Avatar sx={{ bgcolor: deepOrange[500], width: 128, height: 128 }}>
          {/* {name.split(' ')[0][0]}{name.split(' ')[1][0]} */}
          {name ? name.match(/\b(\w)/g).join('') : ""} 
        </Avatar>
        <Box component="form" sx={{ display: 'grid', gridAutoFlow: 'row', gridTemplateColumns: 'repeat(6, 1fr)', gridTemplateRows: 'repeat(2, 1fr)', }}>
        <FormControl sx={{ m: 1, gridRow: '1', gridColumn: 'span 3' }}>
        <TextField
          // InputProps={{ readOnly: true }}
          disabled
          id="ad-outlined-disabled"
          label="Ad Soyad"
          size="small"
          defaultValue={name}
          value={name}
        />
        </FormControl>
        <FormControl sx={{ m: 1, gridRow: '1', gridColumn: 'span 3' }}>
        <TextField
          // InputProps={{ readOnly: true }}
          disabled
          id="email-outlined-disabled"
          label="Email Adresi"
          size="small"
          defaultValue={mail}
          value={mail}
        />
        </FormControl>
        <FormControl sx={{ m: 1, gridRow: '2', gridColumn: 'span 3' }}>
        <TextField
          name="password"
          id="password"
          label="Yeni Şifre"
          type="password"
          size="small"
          // autoComplete="current-password"
          value={pass}
          onChange={handleChangePass}
        />
        </FormControl>
        <FormControl sx={{ m: 1, gridRow: '2', gridColumn: 'span 3' }}>
        <Button 
          onClick={() => { handleSavePass(); }}
          variant="contained" 
          endIcon={<SaveIcon />} 
          // sx={{ minWidth: 150 }}
        >
          Kaydet
        </Button>
        </FormControl>
        </Box>
      </Stack>
    </div>
  );
}
