import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import AddIcon from '@mui/icons-material/AddCircleOutline';

import axios from 'axios';

export default function DialogSelect({addRow}) {
  const [open, setOpen] = React.useState(false);
  const [aciklama, setAciklama] = React.useState("");
  const [daysleft, setDaysleft] = useState(0);
  const term = new Date().getFullYear();

  //ELEYEN USER ID
  const user = sessionStorage.getItem('user');
  
  const [day, setDay] = useState(1);
  function treatAsUTC(date) {
    var result = new Date(date);
    result.setMinutes(result.getMinutes() - result.getTimezoneOffset());
    return result;
  }
  function daysBetween(start, end) {
      var millisecondsPerDay = 24 * 60 * 60 * 1000;
      return 1 + ((treatAsUTC(end) - treatAsUTC(start)) / millisecondsPerDay);
  }
  const [startDate, setStartDate] = useState(new Date(new Date().setHours(3, 0, 0, 0)).toISOString().split('T')[0]);
  const [endDate, setEndDate] = useState(new Date(new Date().setHours(3, 0, 0, 0)).toISOString().split('T')[0]);
  const [unixStartDate, setUnixStartDate] = useState(Math.floor(new Date(new Date().setHours(0, 0, 0, 0)).getTime() / 1000));
  const [unixEndDate, setUnixEndDate] = useState(Math.floor(new Date(new Date().setHours(0, 0, 0, 0)).getTime() / 1000));
  
  const handleChangeStartDate = (event) => {
    setStartDate(new Date(event.target.value).toISOString().split('T')[0]);
    setUnixStartDate(Math.floor(new Date(event.target.value).getTime() / 1000) - 10800); //+3GMT için sn çıkarma işlemi
    setDay(daysBetween(event.target.value, endDate));
  };

  const handleChangeEndDate = (event) => {
    setEndDate(new Date(event.target.value).toISOString().split('T')[0]);
    setUnixEndDate(Math.floor(new Date(event.target.value).getTime() / 1000) - 10800); //+3GMT için sn çıkarma işlemi
    setDay(daysBetween(startDate, event.target.value));
  };

  const handleChangeAciklama = (event) => {
    setAciklama(event.target.value);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setOpen(false);
    }
  };

  const handleAdd = () => {
    let isError = false;
    if(unixStartDate > Math.floor((new Date(endDate).getTime() / 1000) - 10800)
    || unixEndDate < Math.floor((new Date(startDate).getTime() / 1000) - 10800)){
      isError = true;
      alert("Geçerli bir tarih aralığı giriniz!");
    } 
    if(!(aciklama)){
      isError = true;
      alert("Geçerli bir açıklama giriniz!");
    } 
    if(!(user)){
      isError = true;
      alert("Kullanıcı tanınmadı, yöneticiye ulaşınız.");
    } 
    if(!(day > 0)){
      isError = true;
      alert("Seçilen gün sayısı geçersiz, yöneticiye ulaşınız.");
    } 
    if(!isError){
      handleAdd2();
    }
  }

  const handleAdd2 = async () => {

    const options = {
      headers: {
          'Content-Type': 'application/json',
      }
    };
    
    const data = { startDate: unixStartDate, endDate: unixEndDate, consultant: user, aciklama: aciklama };
    await axios.post('https://aktivite-app.herokuapp.com/izinler', data, options)
    .then(function (response) {
      // console.log(response);
      addRow({  id: response.data.message, 
                startdate: unixStartDate,
                enddate: unixEndDate,
                aciklama: aciklama,
              });
      handleAdd3();
      // alert(response.data.message + " " + textValue + " başarıyla eklendi");
    })
    .catch(function (error) {
      console.log(error);
      alert(error);
    });

    setOpen(false);
    setAciklama("");
  };

  const handleAdd3 = async () => {
    const data2 = { consultant: user, day: day, type: "sub" };
    await axios.patch(`https://aktivite-app.herokuapp.com/izinsayilari`, data2)
    .then(function (response) {
      // console.log(response);
    })
    .catch(function (error) {
      console.log(error);
      alert(error);
    });
  };

  useEffect(() => {
    const fetchData = async () => { 
      const res = await fetch(
        `https://aktivite-app.herokuapp.com/izinsayilari?term=${term}&uid=${user}`,
      );
      const json = await res.json();
      if(json.data){
        setDaysleft(json.data[0].remain);
      }
    };
    fetchData();
  }, [term, user]);

  return (
    <div>
      <Button onClick={handleClickOpen} startIcon={<AddIcon />}>
        YENİ İZİN GİRİŞİ
      </Button>
      <Dialog disableEscapeKeyDown open={open} onClose={handleClose}>
        <DialogTitle>Tarihleri, başlangıç-bitiş dahil günler olarak seçiniz.</DialogTitle>
        <DialogContent>
          <Box component="form" sx={{ display: 'grid', gridAutoFlow: 'row', gridTemplateColumns: 'repeat(6, 1fr)', gridTemplateRows: 'repeat(2, 1fr)', }}>
            <FormControl sx={{ m: 1, gridRow: '1', gridColumn: 'span 3' }}>
              <TextField
                label="Başlangıç Tarihi"
                id="startDate"
                type="date"
                value={startDate}
                onChange={handleChangeStartDate}
                InputLabelProps={{ shrink: true }} //label'ı oto. üste alır
                InputProps={{
                  inputProps:{ max: endDate }
                }}
              />
            </FormControl>
            <FormControl sx={{ m: 1, gridRow: '1', gridColumn: 'span 3' }}>
              <TextField
                label="Bitiş Tarihi"
                id="endDate"
                type="date"
                value={endDate}
                onChange={handleChangeEndDate}
                InputLabelProps={{ shrink: true }} //label'ı oto. üste alır
                InputProps={{
                  inputProps:{ min: startDate }
                }}
              />
            </FormControl>
            <FormControl sx={{ m: 1, gridRow: '2', gridColumn: 'span 6' }}>
              <TextField
                id="aciklama"
                label="Açıklama"
                multiline
                value={aciklama}
                onChange={handleChangeAciklama}
              />
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <DialogContentText sx={{ mr: 7, mb: 1 }}>Kalan izin hakkınız: {daysleft}</DialogContentText>
          <Button onClick={handleClose}>İptal</Button>
          <Button onClick={handleAdd}>EKLE</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}