import React, { useState, useEffect, useCallback } from 'react';
import { DataGrid, GridToolbarContainer } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Box from '@mui/material/Box';

import SendIcon from '@mui/icons-material/Send';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import DownloadSharpIcon from '@mui/icons-material/DownloadSharp';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import { red, green } from '@mui/material/colors';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { utils, write, writeFile } from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import "../fonts/OpenSans-Regular";

import axios from 'axios';

export default function IzinlerRapor() {
  
  //DELETE ROW
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    selectedRows.length ? setOpen(true) : window.alert("Lütfen en az bir satır seçin")
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleDelete = () => {
    const selectedIDs = new Set(selectionModel);
    setData((r) => r.filter((x) => !selectedIDs.has(x.id)));
    selectedIDs.forEach(async id => {
      await axios.delete(`https://aktivite-app.herokuapp.com/izinsayilari/${id}`)
      .then(function (response) {
        // console.log(response);
      })
      .catch(function (error) {
        console.log(error);
        alert(error);
      });
    });
    setOpen(false);
  };
  
  //ROW SELECTION CHECKBOX
  const [selectedRows, setRowsData] = useState([])
  const [selectionModel, setSelectionModel] = useState([]);

  //EXCEL
  const handleExcel = () => {
    //TÜM DATAYI İNDİRİYOR PAGINATION YAPMIYOR
    const newData = rows.map(row => {
      delete row.id;
      const temp = {
        DonemYili: row.term,
        Danisman: row.consultant,
        GirisTarihi: row.startdate,
        DonemiciToplam: row.total,
        DonemiciKullanilan: row.used,
        ToplamKalan: row.remain
      }
      return temp;
    });
    const workSheet = utils.json_to_sheet(newData);
    const workBook = utils.book_new();
    utils.book_append_sheet(workBook, workSheet, "easyit-rapor");
    //Buffer
    let buf = write(workBook, { bookType:"xlsx", type:"buffer" });
    //Binary string
    write(workBook, { bookType:"xlsx", type:"binary" });
    //Download
    writeFile(workBook, "EasyIT-Oto-Rapor.xlsx");
  }

  //PDF
  const handlePDF = () => {
    const newData = rows.map(row => {
      const temp = {
        term: row.term,
        consultant: row.consultant,
        startdate: row.startdate,
        total: row.total,
        used: row.used,
        remain: row.remain
      }
      return temp;
    });
    const doc = new jsPDF();
    doc.text("EasyIT Rapor", 20, 10);
    doc.autoTable({
      theme: "grid",
      columns: columns.map(col => ({ ...col, dataKey: col.field })),
      body: newData,
      styles: {
        font: 'OpenSans-Regular',
        fontStyle: 'normal',
      }
    });
    doc.save('EasyIT-Oto-Rapor.pdf');
  }

  //TABLE ROW DATA
  const [rows, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const addRow = useCallback((row) => {
    setData((t) => [row, ...t]);
  }, [rows]);

  const [user, setUser] = useState(null);
  const handleChangeUser = (event) => {
    setUser(event.target.value);
  };

  const [term, setTerm] = useState(new Date().getFullYear());
  const handleChangeTerm = (event) => {
    setTerm(event.target.value);
  };

  const fetchData = async () => {
    const res = await fetch(
        `https://aktivite-app.herokuapp.com/izinsayilari?term=${term}&uid=${user}`,
    );
    const json = await res.json();
    if(json.data){
      setData(json.data);
    }
    setLoading(false);
  };
  useEffect(() => {
    fetchData();
  }, []);
  
  const columns = [
    // { field: 'id', headerName: 'ID', width: 70 },
    {
      field: 'term',
      headerAlign: 'center',
      headerName: 'Dönem Yılı',
      minWidth: 70,
      flex: 1,
      editable: false,
      align: 'center',
    },
    { field: 'consultant', headerName: 'Danışman', width: 130, flex: 1.5, editable: false },
    {
      field: 'startdate',
      headerAlign: 'center',
      headerName: 'Başlangıç Tarihi',
      type: 'date',
      minWidth: 130,
      flex: 1,
      editable: false,
      align: 'center',
      valueFormatter: (params) => {
        if (params.value == "" || params.value == null) { return ""; }
        const valueFormatted = new Date(params.value * 1000).toLocaleDateString();
        return `${valueFormatted}`;
      }
    },
    {
      field: 'total',
      headerAlign: 'center',
      headerName: 'Dönemiçi Toplam',
      type: 'number',
      minWidth: 70,
      flex: 1,
      editable: false,
      align: 'center',
    },
    {
      field: 'used',
      headerAlign: 'center',
      headerName: 'Dönemiçi Kullanılan',
      type: 'number',
      minWidth: 70,
      flex: 1,
      editable: false,
      align: 'center',
    },
    {
      field: 'remain',
      headerAlign: 'center',
      headerName: 'Kalan Toplam',
      type: 'number',
      minWidth: 70,
      flex: 1,
      editable: false,
      align: 'center',
    }
  ];

  const [userArray, setUserArray] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const res = await fetch(
        `https://aktivite-app.herokuapp.com/kullanicilar?sort=ad`,
      );
      const json = await res.json();
      setUserArray(json.data);
    };
    fetchData();
  }, []);
  let users = userArray.map((item, i) => (<MenuItem value={item.id}>{item.ad}</MenuItem>));

  const handleAddYear = async () => {
    let users = userArray.map((item, i) => {
      const zatenEkliMi = rows.find(element => (element.term == new Date().getFullYear() && element.consultant == item.ad));
      if(!zatenEkliMi){
        const calismaSuresi = item.giristarihi ? ((Math.floor(Date.now() / 1000) - item.giristarihi) / (24 * 60 * 60)) : 0;
        if( calismaSuresi >= 365 && calismaSuresi <= 1825)
          return {consultant: item.id, danisman: item.ad, startdate: item.giristarihi, term: new Date().getFullYear(), total: 14, used: 0}
        if( calismaSuresi > 1825 && calismaSuresi <= 5475 ) //iflerin yeri değişebilir böylelikle and kalkar
          return {consultant: item.id, danisman: item.ad, startdate: item.giristarihi, term: new Date().getFullYear(), total: 20, used: 0}
        if( calismaSuresi > 5475 )
          return {consultant: item.id, danisman: item.ad, startdate: item.giristarihi, term: new Date().getFullYear(), total: 26, used: 0}
      }
    });
    
    const options = {
      headers: {
          'Content-Type': 'application/json',
      }
    };
    
    for (var i=0; i<users.length; i++) {
      if(users[i]){
        await axios.post('https://aktivite-app.herokuapp.com/izinsayilari', users[i], options)
        .then(function (response) {
          // console.log(response);
          addRow({  id: response.data.message, 
                    term: users[i].term,
                    consultant: users[i].danisman,
                    startdate: users[i].startdate,
                    total: users[i].total,
                    used: users[i].used,
                    remain: "Hesaplanıyor"
                  });
          // alert(response.data.message + " " + textValue + " başarıyla eklendi");
        })
        .catch(function (error) {
          console.log(error);
          alert(error);
        });
      };
    };
  };

  const [pageSize, setPageSize] = React.useState(25);

  //EDIT ROW
  const [openEdit, setOpenEdit] = useState(false);
  const handleClickEdit = () => {
    selectedRows.length == 1 ? setOpenEdit(true) : window.alert("Lütfen 1 satır seçin")
  };
  function CustomEditRow(){
    const handleCloseEdit = (event, reason) => {
      if (reason !== 'backdropClick') {
        setOpenEdit(false);
      }
    };
    const handleEdit = async () => {
      const id = selectedRows[0].id;  
      const data = { total: total, used: used };
      
      await axios.put(`https://aktivite-app.herokuapp.com/izinsayilari/${id}`, data)
      .then(function (response) {
        // console.log(response);

        const newRow = {  
          id: selectedRows[0].id, 
          term: selectedRows[0].term,
          consultant: selectedRows[0].consultant,
          startdate: selectedRows[0].startdate,
          total: total,
          used: used,
          remain: "Hesaplanıyor"
        };
        const selectedIDs = new Set(selectionModel);
        setData((r) => r.filter((x) => !selectedIDs.has(x.id)));
        setData((t) => [newRow, ...t]);
      })
      .catch(function (error) {
        console.log(error);
        alert(error);
      });
  
      setOpenEdit(false);
    };

    const consultant = selectedRows[0] ? selectedRows[0].consultant : "";
    const term = selectedRows[0] ? selectedRows[0].term : "";
    const [total, setTotal] = React.useState(selectedRows[0] ? selectedRows[0].total : null);
    const [used, setUsed] = React.useState(selectedRows[0] ? selectedRows[0].used : null);

    const handleChangeTotal = (event) => {
      setTotal(Number(event.target.value));
    };
    const handleChangeUsed = (event) => {
      setUsed(Number(event.target.value));
    };
    
    return (
      <Dialog disableEscapeKeyDown open={openEdit} onClose={handleCloseEdit}>
      <DialogTitle>Kullanıcı izin bilgilerini aşağıdan düzenleyebilirsiniz:</DialogTitle>
      <DialogContent>
        <Box component="form" sx={{ display: 'grid', gridAutoFlow: 'row', gridTemplateColumns: 'repeat(2, 1fr)', gridTemplateRows: 'repeat(2, 1fr)' }}>
          <FormControl sx={{ m: 1, gridRow: '1', gridColumn: 'span 1' }}>
            <TextField
              disabled
              id="ad-outlined-disabled"
              label="Ad Soyad"
              defaultValue={consultant}
            />
          </FormControl>
          <FormControl sx={{ m: 1, gridRow: '1', gridColumn: 'span 1' }}>
            <TextField
              disabled
              id="term-outlined-disabled"
              label="Dönem Yılı"
              type="number"
              defaultValue={term}
            />
          </FormControl>
          <FormControl sx={{ m: 1, gridRow: '2', gridColumn: 'span 1' }}>
            <TextField
              label="Dönemiçi Toplam"
              id="total"
              type="number"
              value={total}
              onChange={handleChangeTotal}
              InputProps={{
                endAdornment: <InputAdornment position="end">Gün</InputAdornment>
              }}
            />
          </FormControl>
          <FormControl sx={{ m: 1, gridRow: '2', gridColumn: 'span 1' }}>
            <TextField
              label="Dönemiçi Kullanılan"
              id="used"
              type="number"
              value={used}
              onChange={handleChangeUsed}
              InputProps={{
                endAdornment: <InputAdornment position="end">Gün</InputAdornment>
              }}
            />
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseEdit}>İptal</Button>
        <Button onClick={handleEdit}>Düzenle</Button>
      </DialogActions>
    </Dialog>
    )
  }

  const CustomToolbar = () => {
    const buttonBaseProps = {
      color: 'primary',
      size: 'small',
      // startIcon: <ExportIcon />,
    };
  
    return (
      <GridToolbarContainer>
        <Button
          {...buttonBaseProps}
          onClick={handleExcel}
          startIcon={<DownloadSharpIcon color="primary" />}
          sx={{ mx: 1 }}
        >
          Export to Excel
        </Button>

        <Divider orientation="vertical" variant="middle" flexItem />

        <Button
          {...buttonBaseProps}
          onClick={handlePDF}
          startIcon={<DownloadSharpIcon color="primary" />}
          sx={{ mx: 1 }}
        >
          Download PDF
        </Button>

        <Divider orientation="vertical" variant="middle" flexItem />

        <div style={{
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap',
        }}>
          <Button
            {...buttonBaseProps}
            onClick={handleClickEdit}
            startIcon={<EditOutlinedIcon color={green[500]} />}
            sx={{ mx: 1, color: green[500] }}
          >
            Düzenle
          </Button>
          <CustomEditRow />
        </div>

        <Divider orientation="vertical" variant="middle" flexItem sx={{ mx: 1 }} />
        
        <div style={{
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap',
        }}>
          <Button
            {...buttonBaseProps}
            onClick={handleClickOpen}
            startIcon={<DeleteOutlinedIcon color={red[500]} />}
            sx={{ color: red[500] }}
          >
            Sil
          </Button>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Seçilen satırları silmek istediğinize emin misiniz?"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {selectedRows.map((item) => {
                  return <p>{item.term} - {item.consultant} - {item.total} | {item.used} | {item.remain}</p>
                })}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>İptal</Button>
              <Button onClick={handleDelete}>SİL</Button>
            </DialogActions>
          </Dialog>
        </div>
      </GridToolbarContainer>
    );
  };

  return (
    <div style={{ height: '90%', width: '100%' }}>
      <Stack 
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        sx={{ mb: 2 }}
      >
        <TextField
          label="Dönem Yılı"
          id="efor"
          type="number"
          value={term}
          onChange={handleChangeTerm}
          size="small"
          // sx={{ minWidth: 200 }}
          sx={{ flex: 1 }}
          InputProps={{
            // inputMode: 'numeric', pattern: '[0-9]*',
            inputProps: { min: "2015" },
            endAdornment: <InputAdornment position="end">Yılı</InputAdornment>
          }}
        />
        <TextField
          select
          id="user-select"
          label="Danışman"
          value={user}
          onChange={handleChangeUser}
          size="small"
          // sx={{ minWidth: 200 }}
          sx={{ flex: 1.5 }}
        >
          {users}
        </TextField>
        <Button 
          onClick={() => { 
            setUser(null); 
            setTerm(new Date().getFullYear());
          }}
          variant="contained" 
          endIcon={<SettingsBackupRestoreIcon />} 
          // sx={{ minWidth: 150 }}
          sx={{ flex: 1 }}
        >
          Sıfırla
        </Button>
        <Button 
          onClick={() => { 
            setLoading(true); 
            fetchData(); 
          }}
          variant="contained" 
          endIcon={<SendIcon />} 
          // sx={{ minWidth: 150 }}
          sx={{ flex: 1 }}
        >
          Getir
        </Button>
        <Divider orientation="vertical" variant="middle" flexItem sx={{ mx: 1 }} />
        <Button 
          onClick={handleAddYear}
          variant="contained" 
          startIcon={<PublishedWithChangesIcon />} 
          // sx={{ minWidth: 150 }}
          sx={{ flex: 2 }}
        >
          {new Date().getFullYear()} için eksikleri otomatik tamamla
        </Button>
      </Stack>
      <DataGrid
        rows={rows}
        columns={columns}
        // pageSize={5}
        // rowsPerPageOptions={[5]}
        // checkboxSelection
        // editMode="row"
        //Store Data from the row in another variable
        onSelectionModelChange = {(id) => {
          setSelectionModel(id);
          const selectedIDs = new Set(id);
          const selectedRowData = rows.filter((row) =>
            selectedIDs.has(row.id)
          );
          setRowsData(selectedRowData)
        }}
        pageSize={pageSize}
        onPageSizeChange={(newPage) => setPageSize(newPage)}
        pagination
        components={{ Toolbar: CustomToolbar }}
        loading={loading}
      />
    </div>
  );
}
