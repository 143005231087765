import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import AddIcon from '@mui/icons-material/AddCircleOutline';

import axios from 'axios';

export default function DialogSelect({addRow}) {
  const [open, setOpen] = React.useState(false);
  const [customer, setCustomer] = React.useState("");
  const [partner, setPartner] = React.useState("");
  const [proje, setProje] = React.useState("");
  const [efor, setEfor] = React.useState(0);
  
  const [customerArray, setCustomerArray] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const res = await fetch(
        'https://aktivite-app.herokuapp.com/musteriler',
      );
      const json = await res.json();
      setCustomerArray(json.data);
    };
    fetchData();
  }, [setCustomerArray]);
  let customers = customerArray.map((item, i) => (<MenuItem value={item.id}>{item.name}</MenuItem>));
  
  const [partnerArray, setPartnerArray] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const res = await fetch(
        'https://aktivite-app.herokuapp.com/partnerler',
      );
      const json = await res.json();
      setPartnerArray(json.data);
    };
    fetchData();
  }, [setPartnerArray]);
  let partners = partnerArray.map((item, i) => (<MenuItem value={item.id}>{item.name}</MenuItem>));


  const handleChangeCustomers = (event) => {
    setCustomer(event.target.value);
    // setCustomer(Number(event.target.value) || '');
  };

  const handleChangePartner = (event) => {
    setPartner(event.target.value);
  };

  const handleChangeProje = (event) => {
    setProje(event.target.value);
  };

  const handleChangeEfor = (event) => {
    setEfor(event.target.value);
  };

  //ELEYEN USER ID
  const user = sessionStorage.getItem('user');
  // const [user, setUser] = useState(106);
  // const handleChangeUser = (event) => {
  //   setUser(event.target.value);
  // };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setOpen(false);
      // setCustomer(""); kapatınca clear etmek lazım
      // setPartner("");
    }
  };

  const handleAdd = async () => {
    const options = {
      headers: {
          'Content-Type': 'application/json',
      }
    };
    
    const data = { efor: efor, customer: customer, partner: partner, proje: proje, consultant: user };
    
    await axios.post('https://aktivite-app.herokuapp.com/projeler', data, options)
    .then(function (response) {
      // console.log(response);
      const customerTemp = customerArray.filter(item => item.id == customer)
      const partnerTemp = partnerArray.filter(item => item.id == partner)
      addRow({  id: response.data.message, 
                efor: efor, 
                customer: customerTemp[0].name, 
                partner: partnerTemp[0].name, 
                proje: proje, 
                consultant: 'Siz' 
              });
      // alert(response.data.message + " " + textValue + " başarıyla eklendi");
    })
    .catch(function (error) {
      console.log(error);
      alert(error);
    });

    setOpen(false);
    setCustomer("");
    setPartner("");
    setProje("");
    setEfor(0);
  };

  return (
    <div>
      <Button onClick={handleClickOpen} startIcon={<AddIcon />}>
        YENİ PROJE GİRİŞİ
      </Button>
      <Dialog disableEscapeKeyDown open={open} onClose={handleClose}>
        <DialogTitle>Proje Girişi yapabilmek için bilgileri eksiksiz doldurunuz</DialogTitle>
        <DialogContent>
          <Box component="form" sx={{ display: 'grid', gridAutoFlow: 'row'}}>
            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <TextField
                label="Hedeflenen Efor"
                id="efor"
                type="number"
                value={efor}
                onChange={handleChangeEfor}
                // sx={{ m: 1, width: '25ch' }}
                InputProps={{
                  // inputMode: 'numeric', pattern: '[0-9]*',
                  inputProps: { min: "0" },
                  endAdornment: <InputAdornment position="end">Saat</InputAdornment>
                }}
              />
            </FormControl>
            <FormControl sx={{ m: 1, minWidth: 120 }}>
            {/* <FormControl sx={{ m: 1, gridRow: '2', gridColumn: 'span 2' }}> */}
            <InputLabel htmlFor="customer-select-label">Müşteri</InputLabel>
            <Select
              labelId="customer-select-label"
              id="customer-select"
              value={customer}
              onChange={handleChangeCustomers}
              input={<OutlinedInput label="Müşteri" />}
            >
              {customers}
            </Select>
            </FormControl>
            <FormControl sx={{ m: 1, minWidth: 120 }}>
            {/* <FormControl sx={{ m: 1, gridRow: '2', gridColumn: 'span 2' }}> */}
            <InputLabel id="partner-select-label">Partner</InputLabel>
            <Select
              labelId="partner-select-label"
              id="partner-select"
              value={partner}
              onChange={handleChangePartner}
              input={<OutlinedInput label="Partner" />}
            >
              {partners}
            </Select>
            </FormControl>
            {/* <FormControl sx={{ m: 1, minWidth: 120 }}>
            <InputLabel id="proje-select-label">Proje</InputLabel>
            <Select
                labelId="proje-select-label"
                id="proje-select"
                value={proje}
                onChange={handleChangeProje}
                input={<OutlinedInput label="Proje" />}
            >
                {projeler}
            </Select>
            </FormControl> */}
            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <TextField
                id="proje-input"
                label="Proje Tipi"
                value={proje}
                onChange={handleChangeProje}
              />
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>İptal</Button>
          <Button onClick={handleAdd}>EKLE</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}