import React, { useState, useEffect } from 'react';
import { DataGrid, GridToolbarContainer } from '@mui/x-data-grid';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import Select from '@mui/material/Select';

import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControl from '@mui/material/FormControl';

import DownloadSharpIcon from '@mui/icons-material/DownloadSharp';
import RefreshIcon from '@mui/icons-material/Refresh';
import SendIcon from '@mui/icons-material/Send';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';

import { utils, write, writeFile } from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import "../fonts/OpenSans-Regular";

function isOverflown(element) {
  return (
    element.scrollHeight > element.clientHeight ||
    element.scrollWidth > element.clientWidth
  );
}

const GridCellExpand = React.memo(function GridCellExpand(props) {
  const { width, value } = props;
  const wrapper = React.useRef(null);
  const cellDiv = React.useRef(null);
  const cellValue = React.useRef(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showFullCell, setShowFullCell] = React.useState(false);
  const [showPopper, setShowPopper] = React.useState(false);

  const handleMouseEnter = () => {
    const isCurrentlyOverflown = isOverflown(cellValue.current);
    setShowPopper(isCurrentlyOverflown);
    setAnchorEl(cellDiv.current);
    setShowFullCell(true);
  };

  const handleMouseLeave = () => {
    setShowFullCell(false);
  };

  React.useEffect(() => {
    if (!showFullCell) {
      return undefined;
    }

    function handleKeyDown(nativeEvent) {
      // IE11, Edge (prior to using Bink?) use 'Esc'
      if (nativeEvent.key === 'Escape' || nativeEvent.key === 'Esc') {
        setShowFullCell(false);
      }
    }

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [setShowFullCell, showFullCell]);

  return (
    <Box
      ref={wrapper}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      sx={{
        alignItems: 'center',
        lineHeight: '24px',
        width: 1,
        height: 1,
        position: 'relative',
        display: 'flex',
      }}
    >
      <Box
        ref={cellDiv}
        sx={{
          height: 1,
          width,
          display: 'block',
          position: 'absolute',
          top: 0,
        }}
      />
      <Box
        ref={cellValue}
        sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
      >
        {value}
      </Box>
      {showPopper && (
        <Popper
          open={showFullCell && anchorEl !== null}
          anchorEl={anchorEl}
          style={{ width, marginLeft: -17 }}
        >
          <Paper
            elevation={1}
            style={{ minHeight: wrapper.current.offsetHeight - 3 }}
          >
            <Typography variant="body2" style={{ padding: 8 }}>
              {value}
            </Typography>
          </Paper>
        </Popper>
      )}
    </Box>
  );
});

GridCellExpand.propTypes = {
  value: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
};

function renderCellExpand(params) {
  return (
    <GridCellExpand value={params.value || ''} width={params.colDef.computedWidth} />
  );
}

renderCellExpand.propTypes = {
  /**
   * The column of the row that the current cell belongs to.
   */
  colDef: PropTypes.object.isRequired,
  /**
   * The cell value, but if the column has valueGetter, use getValue.
   */
  value: PropTypes.string.isRequired,
};



export default function DataTable() {

  //EXCEL
  const handleExcel = () => {
    //TÜM DATAYI İNDİRİYOR PAGINATION YAPMIYOR
    const newData = rows.map(row => {
      delete row.id;
      delete row.cid;
      delete row.pid;
      delete row.proje_id;
      //Excel 1st row header için kolay çözüm:
      const temp = {
        Tarih: row.date,
        Musteri: row.customer,
        Partner: row.partner,
        Proje: row.proje,
        Efor: row.efor,
        Olusturan: row.consultant,
        Duzenleme: row.is_modified,
        DuzenlemeTarih: row.mod_date,
        Duzenleyen: row.mod_user,
        Aciklama: row.aciklama,
      }
      return temp;
    });
    const workSheet = utils.json_to_sheet(newData);
    const workBook = utils.book_new();
    utils.book_append_sheet(workBook, workSheet, "easyit-rapor");
    //Buffer
    let buf = write(workBook, { bookType:"xlsx", type:"buffer" });
    //Binary string
    write(workBook, { bookType:"xlsx", type:"binary" });
    //Download
    writeFile(workBook, "EasyIT-Oto-Rapor.xlsx");
  }

  //PDF
  const handlePDF = () => {
    const doc = new jsPDF();
    doc.text("EasyIT Rapor", 20, 10);
    doc.autoTable({
      theme: "grid",
      columns: columns.map(col => ({ ...col, dataKey: col.field })),
      body: rows,
      styles: {
        font: 'OpenSans-Regular',
        fontStyle: 'normal',
      }
    });
    doc.save('EasyIT-Oto-Rapor.pdf');
  }



  const columns = [
    // { field: 'id', headerName: 'ID', width: 70 },
    {
      field: 'date',
      headerAlign: 'center',
      headerName: 'Tarih',
      type: 'date',
      // width: 130,
      flex: 1,
      editable: false,
      align: 'center'
    },
    { field: 'customer', headerName: 'Müşteri', flex: 1, editable: false, renderCell: renderCellExpand },
    { field: 'partner', headerName: 'Partner', flex: 1, editable: false, renderCell: renderCellExpand },
    { field: 'proje', headerName: 'Proje', flex: 1, editable: false, renderCell: renderCellExpand },
    {
      field: 'aciklama',
      headerName: 'Açıklama',
      description: 'Proje Açıklaması',
      sortable: false,
      flex: 3,
      editable: false,
      // valueGetter: (params) =>
      //   `${params.row.firstName || ''} ${params.row.lastName || ''}`,
      renderCell: renderCellExpand
    },
    {
      field: 'efor',
      headerAlign: 'center',
      headerName: 'Efor(Saat)',
      type: 'number',
      // width: 90,
      flex: 0.8,
      editable: false,
      align: 'center'
    },
    { field: 'consultant', headerName: 'Ekleyen', flex: 1, editable: false, renderCell: renderCellExpand },
    { field: 'is_modified', headerAlign: 'center', headerName: 'Güncellendi Mi', flex: 1, editable: false, align: 'center' },
    {
      field: 'mod_date',
      headerAlign: 'center',
      headerName: 'Gün. Tarihi',
      description: 'Güncelleme Tarihi',
      type: 'date',
      flex: 1,
      editable: false,
      align: 'center'
    },
    { field: 'mod_user', headerName: 'Güncelleyen', flex: 1, editable: false, renderCell: renderCellExpand }
  ];

  //UNIX TIMESTAMP
  
  // const todaysDate = new Date();
  // const minDate = new Date();
  // minDate.setMonth(minDate.getMonth() - 1);
  // // 31 marttan geri dönünce 31 şubat yani 3 mart yapmaması için kod
  // if (minDate.getMonth() == todaysDate.getMonth()) 
  //   minDate.setDate(0);
  // minDate.setDate(1);
  // minDate.setHours(0, 0, 0, 0);

  // const defaultDate = todaysDate.toISOString().split('T')[0];
  // const minInputDate = minDate.toISOString().split('T')[0];


  const [maxDate, setMaxDate] = useState(Math.floor(new Date().getTime() / 1000));
  const [defaultDate, setDefaultDate] = useState(new Date().toISOString().split('T')[0]);
  const handleChangeMaxDate = (event) => {
    setMaxDate(Math.floor(new Date(event.target.value).getTime() / 1000));
    setDefaultDate(new Date(event.target.value).toISOString().split('T')[0]);
  };
  const [minDate, setMinDate] = useState(Math.floor(new Date(new Date(new Date().setDate(1)).setHours(0, 0, 0, 0)).getTime() / 1000));
  const [minInputDate, setMinInputDate] = useState(new Date(new Date(new Date().setDate(1)).setUTCHours(0, 0, 0, 0)).toISOString().split('T')[0]);
  const handleChangeMinDate = (event) => {
    // console.log(Math.floor(new Date(event.target.value).getTime() / 1000) - 10800);
    // console.log(new Date(event.target.value).getTime() / 1000);
    setMinDate(Math.floor(new Date(event.target.value).getTime() / 1000) - 10800); //+3GMT için sn çıkarma işlemi
    setMinInputDate(new Date(event.target.value).toISOString().split('T')[0]);
  };
  
  const [user, setUser] = useState("");
  const handleChangeUser = (event) => {
    setUser(event.target.value);
  };
  const [customer, setCustomer] = useState("");
  const handleChangeCustomers = (event) => {
    setCustomer(event.target.value);
    setPartner("");
    setProje("");
  };
  const [partner, setPartner] = useState("");
  const handleChangePartner = (event) => {
    setPartner(event.target.value);
    setProje("");
  };
  const [proje, setProje] = useState(""); // "" olmazsa sql query patlıyor!
  const handleChangeProje = (event) => {
    setProje(event.target.value);
  };

  let partners = null;
  let projeler = null;

  const [customerArray, setCustomerArray] = useState([]);
  
  const [projeArray, setProjeArray] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const res = await fetch(
        'https://aktivite-app.herokuapp.com/projeler?sort=customer',
      );
      const json = await res.json();
      
      var lookup = {};
      var items = json.data;
      var result = {};

      for (var item, i = 0; item = items[i++];) {
        var name = item.customer;
        var id = item.cid;

        //sorting için name-id yer değiştirdik
        if (!(id in lookup)) {
          lookup[id] = 1;
          result[name] = id;
        }
      }
      // console.log(result)
      setCustomerArray(result);
      // setPartnerArray(result);
      setProjeArray(json.data);

    };
    fetchData();
  }, []);
  let customers = Object.entries(customerArray).map(([key, value]) => (<MenuItem value={value}>{key}</MenuItem>));
  const filtered = projeArray.filter(item => item.cid == customer); //seçilen customer için tüm ihtimaller
  const partnerFiltered = filtered.filter( (item, index) => index === filtered.findIndex( element => element.pid === item.pid)); //seçilen customer için distinct partners
  const projeFiltered = projeArray.filter(item => item.cid == customer && item.pid == partner); // seçilen customer+partner ikilisini içeren projeler


  
  const [partnerRows, setPartnerRows] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const res = await fetch(
        'https://aktivite-app.herokuapp.com/partnerler',
      );
      const json = await res.json();
      if(json.data)
      setPartnerRows(json.data);
    };
    fetchData();
  }, []);
  // console.log(partnerRows);
  // console.log(customer);
  partners = customer
    ? partnerFiltered.map((item) => (<MenuItem value={item.pid}>{item.partner}</MenuItem>))
    : partnerRows.map((item) => (<MenuItem value={item.id}>{item.name}</MenuItem>));
  projeler = projeFiltered.map((item) => (<MenuItem value={item.id}>{item.proje}</MenuItem>));
  // console.log(partners);


  const [rows, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    const res = await fetch(
      `https://aktivite-app.herokuapp.com/aktiviteler?startDate=${minDate}&endDate=${maxDate}&uid=${user}&musteri=${customer}&proje=${proje}&partner=${partner}`,
    );
    const json = await res.json();
    if(json.data){
      for (var i=0; i<json.data.length; i++){ //UNIX Timestamp
        json.data[i].date = new Date(json.data[i].date * 1000).toLocaleDateString();
        if(json.data[i].mod_date)
          json.data[i].mod_date = new Date(json.data[i].mod_date * 1000).toLocaleDateString();
        if(json.data[i].is_modified)
          json.data[i].is_modified = "EVET";
      }
      setData(json.data);
    }
    setLoading(false);
  };
  useEffect(() => {
    fetchData();
  }, [setData]);


  const [userArray, setUserArray] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const res = await fetch(
        `https://aktivite-app.herokuapp.com/kullanicilar?sort=ad`,
      );
      const json = await res.json();
      setUserArray(json.data);
    };
    fetchData();
  }, [setUserArray]);
  let users = userArray.map((item, i) => (<MenuItem value={item.id}>{item.ad}</MenuItem>));
  

  const [pageSize, setPageSize] = React.useState(25);


  // const [editRowsModel, setEditRowsModel] = React.useState({});

  // const handleEditRowsModelChange = React.useCallback((model) => {
  //   setEditRowsModel(model);
  // }, []);


  const CustomToolbar = () => {
    const buttonBaseProps = {
      color: 'primary',
      size: 'small',
      // startIcon: <ExportIcon />,
    };
  
    return (
      <GridToolbarContainer>
        <Button
          {...buttonBaseProps}
          onClick={handleExcel}
          startIcon={<DownloadSharpIcon color="primary" />}
          sx={{ mx: 1 }}
        >
          Export to Excel
        </Button>

        <Divider orientation="vertical" variant="middle" flexItem />

        <Button
          {...buttonBaseProps}
          onClick={handlePDF}
          startIcon={<DownloadSharpIcon color="primary" />}
          sx={{ mx: 1 }}
        >
          Download PDF
        </Button>
      </GridToolbarContainer>
    );
  };

  return (
    <div style={{ height: '90%', width: '100%' }}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        sx={{ mb: 2 }}
      >
        <TextField
          label="Başlangıç Tarihi"
          id="startDate"
          type="date"
          size="small"
          defaultValue={minInputDate}
          value={minInputDate}
          onChange={handleChangeMinDate}
          InputLabelProps={{ shrink: true }} //label'ı oto. üste alır
          // InputProps={{
          //   inputProps:{ max: defaultDate }
          // }}
          // sx={{ minWidth: 150 }}
          sx={{ flex: 1 }}
        />
        <TextField
          label="Bitiş Tarihi"
          id="endDate"
          type="date"
          size="small"
          defaultValue={defaultDate}
          value={defaultDate}
          onChange={handleChangeMaxDate}
          InputLabelProps={{ shrink: true }} //label'ı oto. üste alır
          // InputProps={{
          //   inputProps:{ max: defaultDate }
          // }}
          // sx={{ minWidth: 150 }}
          sx={{ flex: 1 }}
        />
        <TextField
          select
          id="customer-select"
          label="Müşteri"
          value={customer}
          onChange={handleChangeCustomers}
          size="small"
          // sx={{ minWidth: 200 }}
          sx={{ flex: 1 }}
        >
          {customers}
        </TextField>
        <TextField
          select
          id="partner-select"
          label="Partner"
          value={partner}
          onChange={handleChangePartner}
          size="small"
          // sx={{ minWidth: 200 }}
          sx={{ flex: 1 }}
        >
          {partners}
        </TextField>
        <TextField
          select
          id="proje-select"
          label="Proje"
          value={proje}
          onChange={handleChangeProje}
          size="small"
          // sx={{ minWidth: 200 }}
          sx={{ flex: 1 }}
        >
          {projeler}
        </TextField>
        <TextField
          select
          id="user-select"
          label="Danışman"
          value={user}
          onChange={handleChangeUser}
          size="small"
          // sx={{ minWidth: 200 }}
          sx={{ flex: 1 }}
        >
          {users}
        </TextField>
        <Button 
          onClick={() => { 
            setUser(""); 
            setCustomer(""); 
            setPartner(""); 
            setProje(""); 
          }}
          variant="contained" 
          endIcon={<SettingsBackupRestoreIcon />} 
          // sx={{ minWidth: 150 }}
          sx={{ flex: 1 }}
        >
          Sıfırla
        </Button>
        <Button 
          onClick={() => { 
            setLoading(true); 
            fetchData(); 
          }}
          variant="contained" 
          endIcon={<SendIcon />} 
          // sx={{ minWidth: 150 }}
          sx={{ flex: 1 }}
        >
          Getir
        </Button>
      </Stack>
      <DataGrid
        rows={rows}
        columns={columns}
        // pageSize={5}
        // rowsPerPageOptions={[5]}
        pageSize={pageSize}
        onPageSizeChange={(newPage) => setPageSize(newPage)}
        pagination
        components={{ Toolbar: CustomToolbar }}
        loading={loading}
      />
    </div>
  );
}
