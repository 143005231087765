import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';

import axios from 'axios';

export default function DataTable() {


  const [textValue, setTextValue] = useState("");
  const onTextChange = (e) => setTextValue(e.target.value);

  const handleAdd = async () => {
    const options = {
      headers: {
          'Content-Type': 'application/json',
      }
    };
    
  console.log(textValue.length)
  console.log(JSON.parse(textValue));
  console.log(JSON.parse(textValue).length);
  // for(let i = 0; i < textValue.length; i++) {
  //   const data = { customer: textValue[i].customer, partner: textValue[i].partner, proje: textValue[i].proje };
  //   console.log(data);
  // }
  await Promise.all(JSON.parse(textValue).map(
    (json) => {
      const data = { customer: json.customer, partner: json.partner, proje: json.proje, consultant: 600029 };
      // console.log(data);
      axios.post('https://aktivite-app.herokuapp.com/projelerToplu', data, options)
      // axios.post('http://localhost:3001/projelerToplu', data, options)
      .then(function (response) {
        // console.log(response);
      })
      .catch(function (error) {
        console.log(error);
        alert(error);
      });
    }
  ));
    
    
    // axios.post('https://aktivite-app.herokuapp.com/projelerToplu', data, options)
    // .then(function (response) {
    //   // console.log(response);
    // })
    // .catch(function (error) {
    //   console.log(error);
    //   alert(error);
    // });
  };
  
  return (
    <div style={{ height: '90%', width: '100%' }}>
      <Button 
        onClick={handleAdd} 
        variant="contained" 
        endIcon={<SendIcon />} 
        sx={{ mb: 1, minWidth: 150 }}
      >
        Send JSON
      </Button>
      {/* <FormControl sx={{ m: 1 }}> */}
        <TextField 
          required
          id="proje"
          label="JSON Data"
          onChange={onTextChange}
          value={textValue}
          style ={{height: '100%', width: '100%'}} 
          multiline
          rows={20}
        />
      {/* </FormControl> */}
    </div>
  );
}
