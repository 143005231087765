import { useState } from 'react';

export default function useAuth() {
  const getAuth = () => {
    const authString = sessionStorage.getItem('auth');
    // console.log("localstorage: " + authString);
    // const userAuth = JSON.parse(authString);
    return authString
  };

  const [auth, setAuth] = useState(getAuth());
  // console.log(auth);

  const saveAuth = userAuth => {
    // localStorage.setItem('auth', JSON.stringify(userAuth));
    sessionStorage.setItem('auth', userAuth);
    console.log(userAuth);
    setAuth(userAuth);
  };

  return {
    setAuth: saveAuth,
    auth
  }
}