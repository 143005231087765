import React, { useState, useEffect, useCallback } from 'react';
import { DataGrid, GridToolbarContainer } from '@mui/x-data-grid';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import { IconButton } from '@mui/material';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import SimCardDownloadOutlinedIcon from '@mui/icons-material/SimCardDownloadOutlined';
import ExplicitOutlinedIcon from '@mui/icons-material/ExplicitOutlined';
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import DownloadSharpIcon from '@mui/icons-material/DownloadSharp';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { red, green } from '@mui/material/colors';
import Tooltip from '@mui/material/Tooltip';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';

import { utils, write, writeFile } from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import "../fonts/OpenSans-Regular";

import axios from 'axios';

import KullaniciInputs from './KullaniciInputs';


function isOverflown(element) {
  return (
    element.scrollHeight > element.clientHeight ||
    element.scrollWidth > element.clientWidth
  );
}

const GridCellExpand = React.memo(function GridCellExpand(props) {
  const { width, value } = props;
  const wrapper = React.useRef(null);
  const cellDiv = React.useRef(null);
  const cellValue = React.useRef(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showFullCell, setShowFullCell] = React.useState(false);
  const [showPopper, setShowPopper] = React.useState(false);

  const handleMouseEnter = () => {
    const isCurrentlyOverflown = isOverflown(cellValue.current);
    setShowPopper(isCurrentlyOverflown);
    setAnchorEl(cellDiv.current);
    setShowFullCell(true);
  };

  const handleMouseLeave = () => {
    setShowFullCell(false);
  };

  React.useEffect(() => {
    if (!showFullCell) {
      return undefined;
    }

    function handleKeyDown(nativeEvent) {
      // IE11, Edge (prior to using Bink?) use 'Esc'
      if (nativeEvent.key === 'Escape' || nativeEvent.key === 'Esc') {
        setShowFullCell(false);
      }
    }

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [setShowFullCell, showFullCell]);

  return (
    <Box
      ref={wrapper}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      sx={{
        alignItems: 'center',
        lineHeight: '24px',
        width: 1,
        height: 1,
        position: 'relative',
        display: 'flex',
      }}
    >
      <Box
        ref={cellDiv}
        sx={{
          height: 1,
          width,
          display: 'block',
          position: 'absolute',
          top: 0,
        }}
      />
      <Box
        ref={cellValue}
        sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
      >
        {value}
      </Box>
      {showPopper && (
        <Popper
          open={showFullCell && anchorEl !== null}
          anchorEl={anchorEl}
          style={{ width, marginLeft: -17 }}
        >
          <Paper
            elevation={1}
            style={{ minHeight: wrapper.current.offsetHeight - 3 }}
          >
            <Typography variant="body2" style={{ padding: 8 }}>
              {value}
            </Typography>
          </Paper>
        </Popper>
      )}
    </Box>
  );
});

GridCellExpand.propTypes = {
  value: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
};

function renderCellExpand(params) {
  return (
    <GridCellExpand value={params.value || ''} width={params.colDef.computedWidth} />
  );
}

renderCellExpand.propTypes = {
  /**
   * The column of the row that the current cell belongs to.
   */
  colDef: PropTypes.object.isRequired,
  /**
   * The cell value, but if the column has valueGetter, use getValue.
   */
  value: PropTypes.string.isRequired,
};



export default function DataTable() {

  // //DELETE ROW
  // const [open, setOpen] = useState(false);
  // const handleClickOpen = () => {
  //   selectedRows.length ? setOpen(true) : window.alert("Lütfen en az bir satır seçin")
  // };
  // const handleClose = () => {
  //   setOpen(false);
  // };
  // const handleDelete = () => {
  //   const selectedIDs = new Set(selectionModel);
  //   setData((r) => r.filter((x) => !selectedIDs.has(x.id)));
  //   console.log(selectedIDs);
  //   selectedIDs.forEach(async id => {
  //     await axios.delete(`https://aktivite-app.herokuapp.com/aktiviteler/${id}`)
  //     .then(function (response) {
  //       // console.log(response);
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //       alert(error);
  //     });
  //   });
  //   setOpen(false);
  // };
  
  //ROW SELECTION CHECKBOX
  const [selectedRows, setRowsData] = useState([])
  const [selectionModel, setSelectionModel] = useState([]);

  //EXCEL
  const handleExcel = () => {
    //TÜM DATAYI İNDİRİYOR PAGINATION YAPMIYOR
    const newData = rows.map(row => {
      delete row.sifre;
      return row;
    });
    const workSheet = utils.json_to_sheet(newData);
    const workBook = utils.book_new();
    utils.book_append_sheet(workBook, workSheet, "easyit-rapor");
    //Buffer
    let buf = write(workBook, { bookType:"xlsx", type:"buffer" });
    //Binary string
    write(workBook, { bookType:"xlsx", type:"binary" });
    //Download
    writeFile(workBook, "EasyIT-Oto-Rapor.xlsx");
  }

  //PDF
  const handlePDF = () => {
    const doc = new jsPDF();
    doc.text("EasyIT Rapor", 20, 10);
    doc.autoTable({
      theme: "grid",
      columns: columns.map(col => ({ ...col, dataKey: col.field })),
      body: rows,
      styles: {
        font: 'OpenSans-Regular',
        fontStyle: 'normal',
      }
    });
    doc.save('EasyIT-Oto-Rapor.pdf');
  }
  
  //TABLE ROW DATA
  const [rows, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const addRow = useCallback((row) => {
    setData((t) => [row, ...t]);
  }, [rows]);

  const columns = [
    { field: 'id', headerAlign: 'center', headerName: 'Kullanıcı ID', width: 100, editable: false, align: "center" },
    { field: 'ad', headerName: 'Kullanıcı Adı', width: 130,
    flex: 1, editable: false, renderCell: renderCellExpand },
    { field: 'email', headerName: 'Kullanıcı Email', width: 130,
    flex: 1, editable: false, renderCell: renderCellExpand },
    {
      field: 'yetki',
      headerAlign: 'center', 
      headerName: 'Yetki',
      // type: 'number',
      width: 90,
      flex: 1,
      editable: false,
      align: 'center'
    },
    {
      field: 'giristarihi',
      headerAlign: 'center',
      headerName: 'Giriş Tarihi',
      type: 'date',
      width: 130,
      flex: 1,
      editable: false,
      align: 'center',
      valueFormatter: (params) => {
        if (params.value == "" || params.value == null) { return ""; }
        const valueFormatted = new Date(params.value * 1000).toLocaleDateString();
        return `${valueFormatted}`;
      }
    }
  ];

  useEffect(() => {
    const fetchData = async () => {
      const res = await fetch(
        `https://aktivite-app.herokuapp.com/kullanicilar?sort=yetki`,
      );
      const json = await res.json();
      if(json.data){
        //admin=1 standard=3
        for (var i=0; i<json.data.length; i++) {
          if(json.data[i].yetki == 1)
            json.data[i].yetki = 'Admin'
          if(json.data[i].yetki == 3)
            json.data[i].yetki = 'Standard'
        }
        setData(json.data);
      }
      setLoading(false);
    };
    fetchData();
  }, [setData]);
  

  const [pageSize, setPageSize] = React.useState(25);


  // const [editRowsModel, setEditRowsModel] = React.useState({});

  // const handleEditRowsModelChange = React.useCallback((model) => {
  //   setEditRowsModel(model);
  // }, []);

  const [openEdit, setOpenEdit] = useState(false);
  const handleClickEdit = () => {
    selectedRows.length == 1 ? setOpenEdit(true) : window.alert("Lütfen 1 satır seçin")
  };
  function CustomEditRow(){
    const handleCloseEdit = (event, reason) => {
      if (reason !== 'backdropClick') {
        setOpenEdit(false);
      }
    };
    const handleEdit = async () => {
      const id = selectedRows[0].id;  
      const data = { password: pass, yetki: yetki };
      
      await axios.put(`https://aktivite-app.herokuapp.com/kullanicilar/${id}`, data)
      .then(function (response) {
        // console.log(response);

        const newRow = {  
          id: selectedRows[0].id, 
          ad: selectedRows[0].ad,
          email: selectedRows[0].email,
          yetki: yetki == 1 ? 'Admin' : 'Standard'
        };
        const selectedIDs = new Set(selectionModel);
        setData((r) => r.filter((x) => !selectedIDs.has(x.id)));
        setData((t) => [newRow, ...t]);
      })
      .catch(function (error) {
        console.log(error);
        alert(error);
      });
  
      setOpenEdit(false);
    };

    const ad = selectedRows[0] ? selectedRows[0].ad : "";
    const email = selectedRows[0] ? selectedRows[0].email : "";
    const [pass, setPass] = React.useState("");
    const [yetki, setYetki] = React.useState(selectedRows[0] ? (selectedRows[0].yetki == 'Admin' ? 1 : 3) : null);
    
    const handleChangePass = (event) => {
      setPass(event.target.value);
    };

    const handleChangeYetki = (event) => {
      setYetki(Number(event.target.value) || '');
    };
    
    return (
      <Dialog disableEscapeKeyDown open={openEdit} onClose={handleCloseEdit}>
      <DialogTitle>Kullanıcı bilgilerini aşağıdan düzenleyebilirsiniz:</DialogTitle>
      <DialogContent>
        <Box component="form" sx={{ display: 'grid', gridAutoFlow: 'row', gridTemplateColumns: 'repeat(2, 1fr)', gridTemplateRows: 'repeat(2, 1fr)' }}>
          <FormControl sx={{ m: 1, gridRow: '1', gridColumn: 'span 1' }}>
            <TextField
              InputProps={{ readOnly: true }}
              id="ad-outlined-disabled"
              label="Ad Soyad"
              defaultValue={ad}
            />
          </FormControl>
          <FormControl sx={{ m: 1, gridRow: '1', gridColumn: 'span 1' }}>
            <TextField
              InputProps={{ readOnly: true }}
              id="email-outlined-disabled"
              label="Email Adresi"
              defaultValue={email}
            />
          </FormControl>
          <FormControl sx={{ m: 1, gridRow: '2', gridColumn: 'span 1' }}>
            <TextField
              name="password"
              id="password"
              label="Yeni Şifre"
              type="password"
              // autoComplete="current-password"
              value={pass}
              onChange={handleChangePass}
            />
          </FormControl>
          <FormControl sx={{ m: 1, gridRow: '2', gridColumn: 'span 1' }}>
            <InputLabel id="yetki-select-label">Yetki</InputLabel>
            <Select
              labelId="yetki-select-label"
              id="yetki-select"
              value={yetki}
              onChange={handleChangeYetki}
              input={<OutlinedInput label="Yetki" />}
            >
              <MenuItem value={1}>Admin</MenuItem>
              <MenuItem value={3}>Standard</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseEdit}>İptal</Button>
        <Button onClick={handleEdit}>Düzenle</Button>
      </DialogActions>
    </Dialog>
    )
  }

  
  const CustomToolbar = () => {

    const buttonBaseProps = {
      color: 'primary',
      size: 'small',
      // startIcon: <ExportIcon />,
    };
  
    return (
      <GridToolbarContainer>
        <Button
          {...buttonBaseProps}
          onClick={handleExcel}
          startIcon={<DownloadSharpIcon color="primary" />}
          sx={{ mx: 1 }}
        >
          Export to Excel
        </Button>

        <Divider orientation="vertical" variant="middle" flexItem />

        <Button
          {...buttonBaseProps}
          onClick={handlePDF}
          startIcon={<DownloadSharpIcon color="primary" />}
          sx={{ mx: 1 }}
        >
          Download PDF
        </Button>


        <Divider orientation="vertical" variant="middle" flexItem />

        <div style={{
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap',
        }}>
          <Button
            {...buttonBaseProps}
            onClick={handleClickEdit}
            startIcon={<EditOutlinedIcon color={green[500]} />}
            sx={{ mx: 1, color: green[500] }}
          >
            Düzenle
          </Button>
          <CustomEditRow />
        </div>

        {/* <Divider orientation="vertical" variant="middle" flexItem sx={{ mx: 1 }} />
        
        <div style={{
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap',
        }}>
          <Button
            {...buttonBaseProps}
            onClick={handleClickOpen}
            startIcon={<DeleteOutlinedIcon color={red[500]} />}
            sx={{ color: red[500] }}
          >
            Sil
          </Button>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Seçilen satırları silmek istediğinize emin misiniz?"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {selectedRows.map((item) => {
                  return <p>{item.date} - {item.customer} {item.partner} {item.proje}</p>
                })}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>İptal</Button>
              <Button onClick={handleDelete}>SİL</Button>
            </DialogActions>
          </Dialog>
        </div> */}
      </GridToolbarContainer>
    );
  };

  return (
    <div style={{ height: '90%', width: '100%' }}>
      <KullaniciInputs addRow={addRow}/>
      <DataGrid
        rows={rows}
        columns={columns}
        // pageSize={5}
        // rowsPerPageOptions={[5]}
        // checkboxSelection
        // editMode="row"
        //Store Data from the row in another variable
        onSelectionModelChange = {(id) => {
          setSelectionModel(id);
          const selectedIDs = new Set(id);
          const selectedRowData = rows.filter((row) =>
            selectedIDs.has(row.id)
          );
          setRowsData(selectedRowData)
        }}
        pageSize={pageSize}
        onPageSizeChange={(newPage) => setPageSize(newPage)}
        pagination
        components={{ Toolbar: CustomToolbar }}
        loading={loading}
      />
    </div>
  );
}
