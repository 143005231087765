import React, { useState, useEffect, useCallback } from 'react';
import { DataGrid, GridToolbarContainer } from '@mui/x-data-grid';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import DownloadSharpIcon from '@mui/icons-material/DownloadSharp';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { red, green } from '@mui/material/colors';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';

import { utils, write, writeFile } from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import "../fonts/OpenSans-Regular";

import axios from 'axios';

import ProjeInputs from './ProjeInputs';
import { ConstructionOutlined } from '@mui/icons-material';

function isOverflown(element) {
  return (
    element.scrollHeight > element.clientHeight ||
    element.scrollWidth > element.clientWidth
  );
}

const GridCellExpand = React.memo(function GridCellExpand(props) {
  const { width, value } = props;
  const wrapper = React.useRef(null);
  const cellDiv = React.useRef(null);
  const cellValue = React.useRef(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showFullCell, setShowFullCell] = React.useState(false);
  const [showPopper, setShowPopper] = React.useState(false);

  const handleMouseEnter = () => {
    const isCurrentlyOverflown = isOverflown(cellValue.current);
    setShowPopper(isCurrentlyOverflown);
    setAnchorEl(cellDiv.current);
    setShowFullCell(true);
  };

  const handleMouseLeave = () => {
    setShowFullCell(false);
  };

  React.useEffect(() => {
    if (!showFullCell) {
      return undefined;
    }

    function handleKeyDown(nativeEvent) {
      // IE11, Edge (prior to using Bink?) use 'Esc'
      if (nativeEvent.key === 'Escape' || nativeEvent.key === 'Esc') {
        setShowFullCell(false);
      }
    }

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [setShowFullCell, showFullCell]);

  return (
    <Box
      ref={wrapper}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      sx={{
        alignItems: 'center',
        lineHeight: '24px',
        width: 1,
        height: 1,
        position: 'relative',
        display: 'flex',
      }}
    >
      <Box
        ref={cellDiv}
        sx={{
          height: 1,
          width,
          display: 'block',
          position: 'absolute',
          top: 0,
        }}
      />
      <Box
        ref={cellValue}
        sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
      >
        {value}
      </Box>
      {showPopper && (
        <Popper
          open={showFullCell && anchorEl !== null}
          anchorEl={anchorEl}
          style={{ width, marginLeft: -17 }}
        >
          <Paper
            elevation={1}
            style={{ minHeight: wrapper.current.offsetHeight - 3 }}
          >
            <Typography variant="body2" style={{ padding: 8 }}>
              {value}
            </Typography>
          </Paper>
        </Popper>
      )}
    </Box>
  );
});

GridCellExpand.propTypes = {
  value: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
};

function renderCellExpand(params) {
  return (
    <GridCellExpand value={params.value || ''} width={params.colDef.computedWidth} />
  );
}

renderCellExpand.propTypes = {
  /**
   * The column of the row that the current cell belongs to.
   */
  colDef: PropTypes.object.isRequired,
  /**
   * The cell value, but if the column has valueGetter, use getValue.
   */
  value: PropTypes.string.isRequired,
};



export default function DataTable() {

  // //DELETE ROW
  // const [open, setOpen] = useState(false);
  // const handleClickOpen = () => {
  //   selectedRows.length ? setOpen(true) : window.alert("Lütfen en az bir satır seçin")
  // };
  // const handleClose = () => {
  //   setOpen(false);
  // };
  // const handleDelete = () => {
  //   const selectedIDs = new Set(selectionModel);
  //   setData((r) => r.filter((x) => !selectedIDs.has(x.id)));
  //   console.log(selectedIDs);
  //   selectedIDs.forEach(id => {
  //     axios.delete(`https://aktivite-app.herokuapp.com/projeler/${id}`)
  //     .then(function (response) {
  //       // console.log(response);
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //       alert(error);
  //     });
  //   });
  //   setOpen(false);
  // };
  
  //ROW SELECTION CHECKBOX
  const [selectedRows, setRowsData] = useState([])
  const [selectionModel, setSelectionModel] = useState([]);

  //EXCEL
  const handleExcel = () => {
    //TÜM DATAYI İNDİRİYOR PAGINATION YAPMIYOR
    const newData = rows.map(row => {
      delete row.id;
      delete row.cid;
      delete row.pid;
      const temp = {
        Musteri: row.customer,
        Partner: row.partner,
        Proje: row.proje,
        Efor: row.efor,
        Ekleyen: row.consultant,
      }
      return temp;
    });
    const workSheet = utils.json_to_sheet(newData);
    const workBook = utils.book_new();
    utils.book_append_sheet(workBook, workSheet, "easyit-rapor");
    //Buffer
    let buf = write(workBook, { bookType:"xlsx", type:"buffer" });
    //Binary string
    write(workBook, { bookType:"xlsx", type:"binary" });
    //Download
    writeFile(workBook, "EasyIT-Oto-Rapor.xlsx");
  }

  //PDF
  const handlePDF = () => {
    const doc = new jsPDF();
    doc.text("EasyIT Rapor", 20, 10);
    doc.autoTable({
      theme: "grid",
      columns: columns.map(col => ({ ...col, dataKey: col.field })),
      body: rows,
      styles: {
        font: 'OpenSans-Regular',
        fontStyle: 'normal',
      }
    });
    doc.save('EasyIT-Oto-Rapor.pdf');
  }

  //TABLE ROW DATA
  const [rows, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const addRow = useCallback((row) => {
    setData((t) => [row, ...t]);
  }, [rows]);

  useEffect(() => {
    const fetchData = async () => {
      const res = await fetch(
        'https://aktivite-app.herokuapp.com/projeler',
      );
      const json = await res.json();
      if(json.data)
        setData(json.data);
      setLoading(false);
    };
    fetchData();
  }, []);
  
  const columns = [
    { field: 'id', headerAlign: 'center', headerName: 'Proje ID', width: 100, editable: false, align: "center" },
    { field: 'customer', headerName: 'Müşteri', width: 130,
    flex: 1, editable: false, renderCell: renderCellExpand  },
    { field: 'partner', headerName: 'Partner', width: 130,
    flex: 1, editable: false, renderCell: renderCellExpand  },
    { field: 'proje', headerName: 'Proje', width: 130,
    flex: 1, editable: false, renderCell: renderCellExpand  },
    {
      field: 'efor',
      headerAlign: 'center', 
      headerName: 'Efor(Saat)',
      // type: 'number',
      width: 90,
      flex: 1,
      editable: false,
      align: 'center'
    },
    { field: 'consultant', headerName: 'Ekleyen', width: 130,
    flex: 1, editable: false, renderCell: renderCellExpand  }
  ];
  

  const [pageSize, setPageSize] = React.useState(25);


  // const [editRowsModel, setEditRowsModel] = React.useState({});

  // const handleEditRowsModelChange = React.useCallback((model) => {
  //   setEditRowsModel(model);
  // }, []);

  const [openEdit, setOpenEdit] = useState(false);
  const handleClickEdit = () => {
    selectedRows.length == 1 ? setOpenEdit(true) : window.alert("Lütfen 1 satır seçin")
  };
  function CustomEditRow(){
    const handleCloseEdit = (event, reason) => {
      if (reason !== 'backdropClick') {
        setOpenEdit(false);
      }
    };
    const handleEdit = async () => {    
      const id = selectedRows[0].id;  
      const data = { 
        efor: efor, 
        customer: customer, 
        partner: partner, 
        proje: proje, 
        consultant: user, 
      };
      
      await axios.put(`https://aktivite-app.herokuapp.com/projeler/${id}`, data)
      .then(function (response) {
        // console.log(response);

        const newRow = {  
          id: selectedRows[0].id, 
          efor: efor, 
          cid: customer, 
          customer: customerText.name, 
          pid: partner, 
          partner: partnerText.name, 
          proje: proje, 
          consultant: 'Siz',
        };
        const selectedIDs = new Set(selectionModel);
        setData((r) => r.filter((x) => !selectedIDs.has(x.id)));
        setData((t) => [newRow, ...t]);
      })
      .catch(function (error) {
        console.log(error);
        alert(error);
      });
  
      setOpenEdit(false);
    };
    
    const [customer, setCustomer] = React.useState(selectedRows[0] ? selectedRows[0].cid : null);
    const [partner, setPartner] = React.useState(selectedRows[0] ? selectedRows[0].pid : null);
    const [proje, setProje] = React.useState(selectedRows[0] ? selectedRows[0].proje : null);
    const [efor, setEfor] = React.useState(selectedRows[0] ? selectedRows[0].efor : null);
  
    // const selected = new Set(selectedRows);
    // console.log(selected);

    const [customerArray, setCustomerArray] = useState([]);
    useEffect(() => {
      const fetchData = async () => {
        const res = await fetch(
          'https://aktivite-app.herokuapp.com/musteriler',
        );
        const json = await res.json();
        setCustomerArray(json.data);
      };
      fetchData();
    }, []);
    let customers = customerArray.map((item, i) => (<MenuItem value={item.id}>{item.name}</MenuItem>));

    const [partnerArray, setPartnerArray] = useState([]);
    useEffect(() => {
      const fetchData = async () => {
        const res = await fetch(
          'https://aktivite-app.herokuapp.com/partnerler',
        );
        const json = await res.json();
        setPartnerArray(json.data);
      };
      fetchData();
    }, []);
    let partners = partnerArray.map((item, i) => (<MenuItem value={item.id}>{item.name}</MenuItem>));

    const [customerText, setCustomerText] = React.useState({name: selectedRows[0] ? selectedRows[0].customer : null});
    const [partnerText, setPartnerText] = React.useState({name: selectedRows[0] ? selectedRows[0].partner : null});

    const handleChangeEfor = (event) => {
      setEfor(Number(event.target.value) || '');
    };
    
    //ELEYEN USER ID
    const user = sessionStorage.getItem('user');
    // const [user, setUser] = useState(106);
    // const handleChangeUser = (event) => {
    //   setUser(event.target.value);
    // };
    
    const handleChangeCustomers = (event) => {
      setCustomer(event.target.value);
      setCustomerText(customerArray.find(data => data.id == event.target.value));
    };
  
    const handleChangePartner = (event) => {
      setPartner(event.target.value);
      setPartnerText(partnerArray.find(data => data.id == event.target.value));
    };
  
    const handleChangeProje = (event) => {
      setProje(event.target.value);
    };
  
    return (
      <Dialog disableEscapeKeyDown open={openEdit} onClose={handleCloseEdit}>
      <DialogTitle>Proje Girişinizi aşağıdan düzenleyebilirsiniz:</DialogTitle>
      <DialogContent>
        <Box component="form" sx={{ display: 'grid', gridAutoFlow: 'row', gridTemplateColumns: 'repeat(6, 1fr)', gridTemplateRows: 'repeat(2, 1fr)', }}>
          <FormControl sx={{ m: 1, gridRow: '1', gridColumn: 'span 3' }}>
            <InputLabel htmlFor="customer-select-label">Müşteriler</InputLabel>
            <Select
              labelId="customer-select-label"
              id="customer-select"
              value={customer}
              onChange={handleChangeCustomers}
              input={<OutlinedInput label="Müşteriler" />}
            >
              {customers}
            </Select>
          </FormControl>
          <FormControl sx={{ m: 1, gridRow: '1', gridColumn: 'span 3' }}>
            <InputLabel id="partner-select-label">Partnerler</InputLabel>
            <Select
              labelId="partner-select-label"
              id="partner-select"
              value={partner}
              onChange={handleChangePartner}
              input={<OutlinedInput label="Partnerler" />}
            >
              {partners}
            </Select>
          </FormControl>
          <FormControl sx={{ m: 1, gridRow: '2', gridColumn: 'span 3' }}>
            <TextField
              label="Hedeflenen Efor"
              id="efor"
              type="number"
              value={efor}
              onChange={handleChangeEfor}
              // sx={{ m: 1, width: '25ch' }}
              InputProps={{
                // inputMode: 'numeric', pattern: '[0-9]*',
                inputProps: { min: "0" },
                endAdornment: <InputAdornment position="end">Saat</InputAdornment>
              }}
            />
          </FormControl>
          <FormControl sx={{ m: 1, gridRow: '2', gridColumn: 'span 3' }}>
            <TextField
              id="proje"
              label="Proje"
              value={proje}
              defaultValue={proje}
              onChange={handleChangeProje}
            />
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseEdit}>İptal</Button>
        <Button onClick={handleEdit}>Düzenle</Button>
      </DialogActions>
    </Dialog>
    )
  }

  const CustomToolbar = () => {

    const buttonBaseProps = {
      color: 'primary',
      size: 'small',
      // startIcon: <ExportIcon />,
    };
  
    return (
      <GridToolbarContainer>
        <Button
          {...buttonBaseProps}
          onClick={handleExcel}
          startIcon={<DownloadSharpIcon color="primary" />}
          sx={{ mx: 1 }}
        >
          Export to Excel
        </Button>

        <Divider orientation="vertical" variant="middle" flexItem />

        <Button
          {...buttonBaseProps}
          onClick={handlePDF}
          startIcon={<DownloadSharpIcon color="primary" />}
          sx={{ mx: 1 }}
        >
          Download PDF
        </Button>

        <Divider orientation="vertical" variant="middle" flexItem sx={{ mx: 1 }} />

        <div style={{
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap',
        }}>
          <Button
            {...buttonBaseProps}
            onClick={handleClickEdit}
            startIcon={<EditOutlinedIcon color={green[500]} />}
            sx={{ color: green[500] }}
          >
            Düzenle
          </Button>
          <CustomEditRow />
        </div>

        {/* <Divider orientation="vertical" variant="middle" flexItem />
        
        <div style={{
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap',
        }}>
          <Button
            {...buttonBaseProps}
            onClick={handleClickOpen}
            startIcon={<DeleteOutlinedIcon color={red[500]} />}
            sx={{ mx: 1, color: red[500] }}
          >
            Delete Row
          </Button>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Seçilen satırları silmek istediğinize emin misiniz?"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {selectedRows.map((item) => {
                  return <p>{item.id} - {item.customer} - {item.partner} - {item.proje}</p>
                })}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>İptal</Button>
              <Button onClick={handleDelete}>SİL</Button>
            </DialogActions>
          </Dialog>
        </div> */}
      </GridToolbarContainer>
    );
  };

  return (
    <div style={{ height: '90%', width: '100%' }}>
      <ProjeInputs addRow={addRow}/>
      <DataGrid
        rows={rows}
        columns={columns}
        // pageSize={5}
        // rowsPerPageOptions={[5]}
        // checkboxSelection
        // editMode="row"
        //Store Data from the row in another variable
        onSelectionModelChange = {(id) => {
          setSelectionModel(id);
          const selectedIDs = new Set(id);
          const selectedRowData = rows.filter((row) =>
            selectedIDs.has(row.id)
          );
          setRowsData(selectedRowData)
        }}
        // editRowsModel={editRowsModel}
        // onEditRowsModelChange={handleEditRowsModelChange}
        pageSize={pageSize}
        onPageSizeChange={(newPage) => setPageSize(newPage)}
        pagination
        components={{ Toolbar: CustomToolbar }}
        loading={loading}
      />
      {/* <Typography sx={{ mx: 1, mt: 1, fontWeight: 'bold' }} variant="caption" display="block" gutterBottom align='left'>
        Düzenleme yapmak için satırlara çift tıklayın. <br />
        Düzenlemeyi bitirmek için ESC ya da Enter tuşunu kullanın.
      </Typography> */}
    </div>
  );
}
