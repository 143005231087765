import { useState, useMemo, useEffect } from "react";
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import { trTR } from '@mui/x-data-grid';
import { trTR as coreTrTR } from '@mui/material/locale';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import Switch from "@mui/material/Switch";
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import SettingsIcon from '@mui/icons-material/Settings';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import ListItemsContent from './ListItems';
import Button from '@mui/material/Button';
import AktiviteTable from './AktiviteTable';
import AktiviteOldTable from './AktiviteOldTable';
import ProjeTable from './ProjeTable';
import ProjeTopluGiris from './ProjeTopluGiris';
import KullaniciTable from './KullaniciTable';
import PartnerTable from './PartnerTable';
import CustomerTable from './CustomerTable';
import RaporTable from './RaporTable';
import Ayarlar from './Ayarlar';
import Ayarlar2 from './Ayarlar2';
import Izinler from './Izinler';
import IzinlerRapor from './IzinlerRapor';
import IzinSayilari from './IzinSayilari';
import Masraflar from './Masraflar';
import useAuth from '../authentication/useAuth';
import NotFound from './NotFound';

import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import { useMediaQuery } from "@mui/material";

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="http://easy-it.com.tr/">
        EasyIT
      </Link>
      {' 2022'}
      {/* {new Date().getFullYear()} */}
      {' ver 1.05'}
    </Typography>
  );
}

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

// const mdTheme = createTheme(
//   // {
//   //   palette: {
//   //     mode: 'dark',
//   //   },
//   // },
//   trTR, 
//   coreTrTR,
// );

function DashboardContent() {

  const [mode, setMode] = useState(sessionStorage.getItem('theme') ? sessionStorage.getItem('theme') : "light");
  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: mode,
        },
      },
      trTR, 
      coreTrTR,
      ),
    [mode]
  );

  const handleTheme = () => {
    setMode(mode === "light" ? "dark" : "light");
    sessionStorage.setItem('theme', mode === "light" ? "dark" : "light");
  }

  const { auth, setAuth } = useAuth();

  const [open, setOpen] = useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };
  const isBigScreen = useMediaQuery("(min-width: 1140px", false);
  useEffect(() => {
    if (isBigScreen) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [isBigScreen]);

  const handleLogout = () => {
    sessionStorage.clear();
    window.location.reload();
  }

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: '24px', // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: '36px',
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>

            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              EasyIT Panel
            </Typography>

            {/* <Switch onChange={() => setMode(mode === "light" ? "dark" : "light")} /> */}
            <IconButton sx={{ ml: 1 }} onClick={handleTheme} color="inherit">
              {theme.palette.mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
            </IconButton>

            <IconButton color="inherit" href="/ayarlar" variant="contained" sx={{ mr: 2 }}>
              <SettingsIcon />
            </IconButton>

            <Button 
              variant="outlined"
              color="inherit"
              onClick={handleLogout}
              style={{ fontWeight: 'bold' }}
            >
              Çıkış Yap
            </Button>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">
            {/* {mainListItems} */}
            <ListItemsContent />
            {/* <Divider sx={{ my: 1 }} />
            {secondaryListItems} */}
          </List>
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />
          <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Paper sx={{ height: 800, p: 2, display: 'flex', flexDirection: 'column' }}>
                  <BrowserRouter>
                    {auth == 1 &&
                    <Routes>
                      <Route path="/" element={<AktiviteTable />} />
                      <Route path="/eski-aktiviteler" element={<AktiviteOldTable />} />
                      <Route path="/proje-giris" element={<ProjeTable />} />
                      <Route path="/proje-toplu-giris" element={<ProjeTopluGiris />} />
                      <Route path="/partnerler" element={<PartnerTable />} />
                      <Route path="/musteriler" element={<CustomerTable />} />
                      <Route path="/kullanicilar" element={<KullaniciTable />} />
                      <Route path="/rapor" element={<RaporTable />} />
                      <Route path="/ayarlar" element={<Ayarlar />} />
                      <Route path="/izinler" element={<Izinler />} />
                      <Route path="/izinler-rapor" element={<IzinlerRapor />} />
                      <Route path="/izin-sayilari" element={<IzinSayilari />} />
                      <Route path="/masraflar" element={<Masraflar />} />
                      <Route path="*" element={<NotFound />} />
                    </Routes>
                    }
                    {auth == 3 &&
                    <Routes>
                      <Route path="/" element={<AktiviteTable />} />
                      <Route path="/izinler" element={<Izinler />} />
                      <Route path="/masraflar" element={<Masraflar />} />
                      <Route path="/ayarlar" element={<Ayarlar2 />} />
                      <Route path="*" element={<NotFound />} />
                    </Routes>
                    }
                  </BrowserRouter>
                </Paper>
              </Grid>
            </Grid>
            <Copyright sx={{ pt: 4 }} />
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default function Dashboard() {
  return <DashboardContent />;
}
