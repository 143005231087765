import React, { useState, useEffect, useCallback } from 'react';
import { DataGrid } from '@mui/x-data-grid';

import IzinlerInputs from './IzinlerInputs';

export default function Izinler() {
  
  //TABLE ROW DATA
  const [rows, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const addRow = useCallback((row) => {
    setData((t) => [row, ...t]);
  }, [rows]);

  //ELEYEN USER ID
  const user = sessionStorage.getItem('user');

  useEffect(() => {
    const fetchData = async () => { 
      const res = await fetch(
        `https://aktivite-app.herokuapp.com/izinler?startDate=null&endDate=null&uid=${user}`,
      );
      const json = await res.json();
      if(json.data){
        setData(json.data);
      }
      setLoading(false);
    };
    fetchData();
  }, [user]);
  
  const columns = [
    // { field: 'id', headerName: 'ID', width: 70 },
    {
      field: 'startdate',
      headerAlign: 'center',
      headerName: 'Başlangıç Tarihi',
      type: 'date',
      minWidth: 130,
      flex: 1,
      editable: false,
      align: 'center',
      valueFormatter: (params) => {
        if (params.value == null) { return ''; }
        const valueFormatted = new Date(params.value * 1000).toLocaleDateString();
        return `${valueFormatted}`;
      }
    },
    {
      field: 'enddate',
      headerAlign: 'center',
      headerName: 'Bitiş Tarihi',
      type: 'date',
      minWidth: 130,
      flex: 1,
      editable: false,
      align: 'center',
      valueFormatter: (params) => {
        if (params.value == null) { return ''; }
        const valueFormatted = new Date(params.value * 1000).toLocaleDateString();
        return `${valueFormatted}`;
      }
    },
    // { field: 'consultant', headerName: 'Ekleyen', width: 130, editable: false },
    {
      field: 'aciklama',
      headerName: 'Açıklama',
      description: 'Açıklama',
      minWidth: 200,
      flex: 4,
      sortable: false,
      editable: false,
    }
  ];

  const [pageSize, setPageSize] = React.useState(25);

  return (
    <div style={{ height: '90%', width: '100%' }}>
      <IzinlerInputs addRow={addRow}/>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={pageSize}
        onPageSizeChange={(newPage) => setPageSize(newPage)}
        disableSelectionOnClick 
        pagination
        loading={loading}
      />
    </div>
  );
}
